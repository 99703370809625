import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CheckboxGroup from "../commons/CheckboxGroup";
import InputText from "../commons/InputText";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import styles from "./styles";
import { getEscritoriosAdvocacia } from "../../actions/juridico";
import { saveUser, getUser } from "../../actions/user";

class Usuario extends React.Component {
  componentDidMount() {
    this.props.getEscritoriosAdvocacia();
  }

  componentDidUpdate() {
    if (this.props.userId) {
      this.props.getUser(this.props.userId);
    }
  }

  save(values) {
    const { userId, saveUser } = this.props;
    saveUser(userId, values, () => this.successHandler());
  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar("Usuário alterado com sucesso!", {
      variant: "success",
    });
    reset();
    handleClose(reload);
  }

  render() {
    const {
      reset,
      classes,
      handleSubmit,
      pristine,
      submitting,
      escritoriosAdvocacia,
    } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Editar usuário</DialogTitle>
        <form
          className={classes.container}
          onSubmit={handleSubmit(this.save.bind(this))}
        >
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={6}>
                <Field name="displayName" component={InputText} label="Nome" />
              </Grid>
              <Grid item sm={12}>
                <CheckboxGroup
                  name="escritorios"
                  title="Escritórios associados"
                  options={escritoriosAdvocacia.map((e) => ({
                    label: e.nome,
                    value: e.id,
                  }))}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => reset() && this.props.handleClose(null)}
              color="default"
            >
              Fechar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  escritoriosAdvocacia: state.juridico.escritoriosAdvocacia,
  userId: state.userState.userId,
  initialValues: state.userState.user,
});

Usuario.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEscritoriosAdvocacia, getUser, saveUser })
)(
  reduxForm({
    form: "usuarioEscritorio",
    enableReinitialize: true,
  })(withSnackbar(Usuario))
);
