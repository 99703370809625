import React, { Component, Fragment } from 'react';
import { connect } from "react-redux"
import _ from "lodash";
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import { storage } from "../../firebase";
import { incluirAnexoDependente } from "../../actions/dependent";

const styles = (theme) => ({
    input: {
        display: 'none'
    }
});

class AnexoDependente extends Component {
    state = { anexos: {} };

    componentDidMount() {
        if (this.props.anexos) {
            this.setState({ anexos: this.props.anexos })
        }
    }

    componentWillUnmount() {
        this.setState({ anexos: null })
    }

    handleCapture = (event) => {
        const file = event.target.files[0];
        const { id } = this.props;
        storage.uploadAnexoDependente(`${id}/${file.name}`, file).then(({ metadata }) => {
            const anexo = { nome: file.name, url: metadata.fullPath };
            this.props.incluirAnexoDependente(id, anexo);
            const { anexos } = this.state;
            anexos[anexo.nome] = anexo;
            this.setState({ anexos: { ...anexos } })
        })
    };

    render() {
        const { classes, id } = this.props;
        const { anexos } = this.state;

        return (
            <Fragment>
                <Grid item container sm={12} spacing={2}>
                    {_.map(anexos, (item, index) => this.renderAnexo(item, index))}
                </Grid>
                <Grid item container sm={12} spacing={2}>
                    <input
                        className={classes.input}
                        id={id}
                        onChange={(event) => this.handleCapture(event)}
                        type="file"
                    />
                    <label htmlFor={id}>
                        <Button color="primary" component="span" size="small" style={{ marginRight: 8 }}>
                            <AttachFile /> Anexar Documento
                        </Button>
                    </label>
                </Grid>
            </Fragment>
        );
    }

    renderAnexo(item, id) {
        const { canEdit } = this.props;

        if (!canEdit) {
            return <Chip size="small" key={item.url} onClick={() => storage.downloadAnexo(item.url)} label={item.nome} clickable variant="outlined" />
        } else {
            return <Chip size="small" key={item.url} onDelete={() => this.handleDelete({ ...item, id })} onClick={() => storage.downloadAnexo(item.url)} label={item.nome} clickable variant="outlined" />
        }
    }

    handleDelete(anexo) {
        const { anexos } = this.state;
        if (anexos && anexos[anexo.id]) {
            delete (anexos[anexo.id])
        }
        this.setState({ anexos: { ...anexos } })
        this.props.handleDelete(anexo)
    }
}

export default connect(null, { incluirAnexoDependente })(withStyles(styles, { withTheme: true })(AnexoDependente))