import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Typography,
  Grid,
  Box,
  Container,
} from '@material-ui/core';
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/core/styles";
import tableI18n from "../TableLocalization";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import { PROFILE } from "../../constants/routes";
import AutoridadeForm from "./autoridade";
import { getAutoridadesPoliticas, deleteAutoridadePolitica, setAutoridadePolitica, setAutoridadePoliticaId } from "../../actions/autoridadePolitica";
import _ from 'lodash'
import * as roles from "../../constants/roles"
import ConfirmDialog from "../commons/ConfirmDialog";

class Autoridades extends React.Component {
  state = {
    open: false,
    openPerfil: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: "",
    isCreatingNew: false,
    politicoId: null
  };

  componentDidMount() {
    this.props.getAutoridadesPoliticas();
  }

  handlePerfilClickOpen = () => {
    this.setState({ open: true, isCreatingNew: true });
  }

  handlePerfilClickClose = (reload) => {
    this.props.setAutoridadePolitica(null)
    this.props.setAutoridadePoliticaId(null)
    this.setState({ open: false, isCreatingNew: false });
    if (reload) {
      this.props.getAutoridadesPoliticas();
    }
  }

  handlePerfilAutoridadeOpen = () => {
    this.setState(prevState => ({ openPerfil: !prevState.openPerfil }));
  }

  handleRowClick = (rowData) => {
    this.props.history.push({
      pathname: `/Autoridades/${rowData.id}`, state: { politicoId: rowData.id }
    });
  }

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage })
  }

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  render() {
    const { classes, loading, authUser } = this.props;
    const politicos = Object.values(this.props.politicos).length > 0
      ? Object.values(this.props.politicos)
        .sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1
          return 0
        })
      : []
    const localization = tableI18n;
    const canDelete = _.includes(authUser.roles, roles.GESTOR_SISTEMA)

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Container maxWidth="xl">
          <Grid container item md={12} justifyContent="flex-end" spacing={2} className={classes.areaOperacoes}>
            <Grid item>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={() => this.props.history.push(PROFILE)}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{ padding: 3, mt: 4 }}>
            <Grid>
              <Typography variant="h5" align="start" gutterBottom style={{ padding: 20 }}>
                PERFIS DE AUTORIDADES
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid container item spacing={3} style={{ marginLeft: 5 }}>
                <Grid item xs={12} sm={2}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Button
                      className={classes.button}
                      color="primary"
                      variant="contained"
                      onClick={
                        this.handlePerfilClickOpen

                      }
                    >
                      Inserir novo perfil
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <MaterialTable
                  columns={[
                    {
                      title: (
                        <Box>
                          <Typography variant="h9">Nome</Typography>
                          <Typography variant="subtitle2">(Cargo)</Typography>
                        </Box>
                      ),
                      field: 'nome',
                      render: rowData => (
                        <Box>
                          <Typography
                            variant="body1"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              this.handleRowClick(rowData)
                            }

                          >
                            {rowData.nome}
                          </Typography>
                          <Typography variant="body2"><i>{this.fetchCargoAtual(rowData)}</i></Typography>
                        </Box>
                      )
                    },
                    { title: "Idade", field: "idade" },
                    { title: "Partido", field: "partido" },
                    { title: "Exerce Mandato", field: "exerce_mandato" },
                  ]}
                  data={politicos}
                  title=''
                  actions={[
                    canDelete ? {
                      icon: 'delete',
                      tooltip: 'Excluir',
                      onClick: (event, row) =>
                        this.handleConfirmOpen(
                          () => this.props.deleteAutoridadePolitica(row.id),
                          `Confirma a exclusão permanente de ${row.nome}?`
                        )
                    } : null
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    padding: 'dense'
                  }}
                  localization={localization}

                />
              </Grid>
            </Grid>
          </Grid>
          <AutoridadeForm
            open={this.state.open}
            handleClose={this.handlePerfilClickClose}
            isCreatingNew={this.state.isCreatingNew}
          />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Container>
      </div>
    );
  }

  fetchCargoAtual(row) {
    const cargos = row?.cargos || [];
    const cargoAtual = cargos.find(cargo => cargo.atual);
    return cargoAtual?.cargo || '';
  }
}




const mapStateToProps = ({ autoridade_politica: { politicos } }) => ({ politicos });

Autoridades.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getAutoridadesPoliticas, deleteAutoridadePolitica, setAutoridadePoliticaId, setAutoridadePolitica }
  ),
)(withRouter(withSnackbar(Autoridades)));
