import Button from "@material-ui/core/Button";
import _ from "lodash";
import Checkbox from '@material-ui/core/Checkbox';
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import tableI18n from "../TableLocalization";
import MaterialTable, { MTableToolbar } from "material-table";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { getReceitas, setReceitaId, setReceita, deleteReceita, atualizarReceita, incluirAnexoReceita } from "../../actions/financial"
import RangeDatePicker from "../commons/RangeDatePicker";
import ConfirmDialog from "../commons/ConfirmDialog";
import SindilegisBar from "../commons/SindilegisBar";
import { downloadXlsx } from "../commons/util";
import ReceitaForm from "./receita";
import EstornoReceita from "./estorno_receita";
import styles from "./styles";
import * as routes from "../../constants/routes";
import * as roles from "../../constants/roles";
import { getDataInicial, getDataFinal, converterParaNumero, formatarValor, formataData, calcularEstorno, somadorDouble } from "./util";
import CreditoReceita from './credito_receita';
import FileUploadReceita from "../commons/FileUploadReceita";
import { storage } from "../../firebase";
import { withSnackbar } from "notistack";

class ListaReceitas extends React.Component {
  state = {
    loading: true,
    open: false,
    fromDate: getDataInicial(),
    toDate: getDataFinal(),
    filteredReceitas: [],
    confirmOpen: false,
    confirmMessage: '',
    confirmAction: Function,
    valoresSelecionados: 0
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    this.props.getReceitas();
  }

  componentWillReceiveProps({ receitas }) {
    if (receitas) {
      this.filterReceitas(this.state.fromDate, this.state.toDate)
    }
  }

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = confirm => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  editReceita(event, id) {
    this.props.setReceitaId(id);
    this.setState({ open: true });
    event.stopPropagation();
  }

  handleOpen() {
    this.props.setReceitaId(null);
    this.props.setReceita(null);
    this.setState({ open: true });
    this.props.getReceitas();
  }

  handleClose() {
    this.setState({ open: false, start: null, end: null });
    this.props.setReceitaId(null);
    this.props.setReceita(null);
    this.props.getReceitas();
  }

  handleDateChange(date) {
    this.setState(date)
    this.filterReceitas(date.fromDate.getTime(), date.toDate.getTime())
  }

  filterReceitas(fromDate, toDate) {
    if (fromDate && toDate) {
      const filteredReceitas = this.props.receitas.filter(e => fromDate <= e.data && toDate >= e.data);
      this.setState({ filteredReceitas });
    }
  }

  selecionarReceita(event, id) {
    const { filteredReceitas } = this.state;
    const index = filteredReceitas.findIndex(d => d.id === id);
    const despesa = { ...filteredReceitas[index], selecionado: !filteredReceitas[index].selecionado }
    filteredReceitas.splice(index, 1, despesa)
    this.setState({ filteredReceitas: [...filteredReceitas] })
    event.stopPropagation();
  }

  deleteAnexo(receita, anexo) {
    this.handleConfirmOpen(() => {
      if (receita.anexos && receita.anexos[anexo.id]) {
        delete (receita.anexos[anexo.id])
      }

      const anexos = receita.anexos
      this.props.atualizarReceita(receita.id, { anexos })
      storage.removerAnexo(anexo.url)
    }, `Confirma exclusão do documento ${anexo.nome}?`)
  }

  render() {
    const { classes, authUser } = this.props;
    const canEdit = _.includes(authUser.roles, roles.ADMIN_FINANCEIRO);
    const valoresSelecionados = this.state.filteredReceitas.filter(d => d.selecionado).map(e => converterParaNumero(e.valor)).reduce((a, b) => a + b, 0);
    return (
      <div>
        <Fab variant="extended" aria-label="delete" className={classes.soma}>{formatarValor(valoresSelecionados)}</Fab>
        <SindilegisBar />
        <Container maxWidth="xl" className={classes.containerBottomPadding}>
          <Grid container justifyContent="space-between" alignItems="center" className={classes.areaOperacoes}>
            <Grid item container spacing={1} md={5} justifyContent="flex-start"  >
              <RangeDatePicker fromDate={this.state.fromDate} toDate={this.state.toDate} onChange={(data) => this.handleDateChange(data)} />
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                color="default"
                variant="contained"
                onClick={() => this.props.history.push(routes.GRAFICOS_RECEITAS)}
              >Relatório</Button>
            </Grid>
            {canEdit && <React.Fragment>
              <Grid item>
                <Button
                  className={classes.button}
                  color="default"
                  variant="contained"
                  onClick={() => this.props.history.push(routes.ITENS_RECEITA)}
                >Itens de receita</Button>
              </Grid>
              <Grid container item md={4} justifyContent="flex-end">
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={() => this.props.history.push(routes.FINANCIAL)}>
                  Voltar
                </Button>
                <Grid item style={{ width: 16 }} />
                <Button className={classes.button} color="primary" variant="contained"
                  size="large" onClick={this.handleOpen}>
                  CADASTRAR RECEITA
                </Button>
              </Grid>
            </React.Fragment>}
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Grid container>
              <ReceitaForm open={this.state.open} handleClose={this.handleClose} />
            </Grid>
          </Grid>
          <MaterialTable
            columns={[
              {
                title: '', field: 'selecionado', render: row => {
                  return (
                    <Checkbox
                      checked={!!row.selecionado}
                      onChange={(event) => this.selecionarReceita(event, row.id)}
                      onClick={e => e.stopPropagation()}
                      inputProps={{
                        'aria-label': 'Selecionar receita',
                      }}
                    />
                  );
                }
              },
              { title: 'Item', field: 'item_receita' },
              { title: 'Tipo', field: 'tipo' },
              { title: 'Descrição', field: 'descricao' },
              { title: 'Valor', field: 'valor', render: (row) => <span>{formatarValor(row.valor)}</span> },
            ]}
            data={this.state.filteredReceitas}
            title="Receitas"
            detailPanel={row => this.renderDetail(row, canEdit)}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: [10, 20, 50, 100],
              exportButton: true,
              exportAllData: true,
              exportCsv: (columns, data) => {

                columns = columns.concat([
                  { title: "Data", field: "data" },
                  { title: "Parcela", field: "parcela" }
                ]).filter(c => !_.isEmpty(c.title))

                data.forEach(d => {
                  d.data = formataData(d.data);
                })

                downloadXlsx(columns, data, 'receitas.xlsx')
              },
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Editar",
                onClick: (event, row) => this.editReceita(event, row.id),
                disabled: !canEdit,
              },
              rowData => {
                if ((!canEdit || (authUser.email !== 'amanda@sindilegis.org.br' && rowData.data_credito))) {
                  return;
                }
                return {
                  icon: "delete",
                  tooltip: "Excluir",
                  onClick: (event, row) => this.handleConfirmOpen(() => this.props.deleteReceita(row.id), 'Confirma exclusão da receita?'),
                }
              }
            ]}
            localization={tableI18n}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <div style={{ padding: '10px' }}>
                    Total: {formatarValor(props.renderData.map(e => calcularEstorno(e)).reduce(somadorDouble, 0))}
                  </div>
                </div>
              ),
            }}
          />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Container>
      </div>
    );
  }

  renderDetail(receita, canEdit) {
    const {
      id,
      descricao,
      data,
      parcela,
      data_credito,
      anexos
    } = receita;
    const { classes, authUser } = this.props;

    return (
      <Grid container spacing={4} className={classes.despesa}>
        <Grid item sm={8}>
          <Typography variant="body2" color="textSecondary">Descrição</Typography>
          <Typography variant="body1">{descricao}</Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography variant="body2" color="textSecondary">
            Parcela
          </Typography>
          <Typography variant="body1">{parcela}</Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography variant="body2" color="textSecondary">Data</Typography>
          <Typography variant="body1">{formataData(data)}</Typography>
        </Grid>
        {canEdit &&
          <Grid item container sm={12} spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item container sm={6} spacing={1}>
              <CreditoReceita receita={receita} />
            </Grid>
            <Grid item container sm={4} spacing={1}>
              <EstornoReceita receita={receita} />
            </Grid>
            <FileUploadReceita
              id={id}
              anexos={anexos}
              receitaCreditada={!!data_credito}
              handleDelete={(anexo) => this.deleteAnexo(receita, anexo)}
              canEdit={canEdit}
              canDelete={authUser.email === 'amanda@sindilegis.org.br'}
              enqueueSnackbar={this.props.enqueueSnackbar}
            />
          </Grid>
        }
      </Grid>
    );
  }
}

const mapStateToProps = ({ financial }) => ({
  receitas: financial.receitas,
  receitaId: financial.receitaId
});

ListaReceitas.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getReceitas, setReceitaId, setReceita, deleteReceita, atualizarReceita, incluirAnexoReceita }
  )
)(withRouter(withSnackbar(ListaReceitas)));
