export default theme => ({
  appBar: {
    flexGrow: 1
  },
  conteudo: {
   minHeight: "70vh" ,
   marginTop: "3em"
  },
  profilesVectors: {
    height: "auto",
    width: "100%"
  }
});
