import React from "react";
import {Field, reduxForm} from "redux-form";
import {compose} from "recompose";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import InputText from "../../commons/InputText";
import {withSnackbar} from "notistack";
import styles from "../styles";
import {adicionarComentarioAtendimento} from "../../../actions/juridico";

class ComentarioAtendimento extends React.Component {

    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
    }

    componentDidMount() {

    }

    save(values) {
        const {atendimento, adicionarComentarioAtendimento} = this.props;
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        console.log(authUser)
        adicionarComentarioAtendimento(atendimento, values.comentario, authUser.email, () => this.successHandler(true))
    }

    successHandler() {
        const {handleClose, enqueueSnackbar} = this.props;
        enqueueSnackbar("Comentário adicionado com sucesso!", {
            variant: "success"
        });
        this.props.reset();
        handleClose();
    }

    cancelar() {
        this.props.reset();
        this.props.handleClose();
    }

    errorHandler() {
        const {enqueueSnackbar} = this.props;
        enqueueSnackbar("Erro ao salvar comentário.", {
            variant: "error"
        })
    }

    render() {
        const {handleSubmit, pristine, invalid, submitting} = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.props.handleClose(true)}
                fullWidth
                maxWidth="md"
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Novo comentário</DialogTitle>
                <form onSubmit={handleSubmit(this.save)}>
                    <DialogContent>
                        <Grid container
                              spacing={2}
                              hidden={false}
                              alignItems="center"
                              justifyContent="center">
                            <Grid item sm={12}>
                                <Field
                                    name="comentario"
                                    component={InputText}
                                    multiline={true}
                                    rows={3}
                                    label="Comentário"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.cancelar.bind(this)}
                            color="default"
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={pristine || submitting || invalid}
                            color="primary">
                            Salvar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

ComentarioAtendimento.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = ({
                             juridico: {atendimento}
                         }) => ({
    atendimento
});

const validate = values => {
    const errors = {};
    const requiredFields = [
        "comentario"
    ];
    requiredFields.forEach(field => {
        if (!values[field] || (Array.isArray(values[field]) && values[field].length === 0)) {
            errors[field] = " Obrigatório";
        }
    });

    return errors;
};

export default compose(
    connect(
        mapStateToProps,
        {adicionarComentarioAtendimento}
    ),
    withStyles(styles)
)(
    reduxForm({
        form: "comentarioAtendimento",
        validate,
        enableReinitialize: true,
    })(withSnackbar(ComentarioAtendimento))
);