/* eslint-disable no-const-assign */
import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Typography,
  Grid,
  Box,
  Container,
  Paper,
} from '@material-ui/core'
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/core/styles";
import { getFiliados } from "../../actions/filiado";
import { enviarPesquisa } from "../../actions/satisfacao";
import tableI18n from "../TableLocalization";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import { PROFILE } from "../../constants/routes";


class Satisfacao extends React.Component {
  state = {
    juridicoSelect: false,
    odontoSelect: false,
    contabilSelect: false,
    psicologicoSelect: false,
    filiados: [],
    filiadosAComunicar: [],
  };

  componentDidMount() {
    this.props.getFiliados()
  }

  handleJuridicoSelect = () => {
    this.setState(prevState => ({ juridicoSelect: !prevState.juridicoSelect }));
  }

  handleOdontoSelect = () => {
    this.setState(prevState => ({ odontoSelect: !prevState.odontoSelect }));
  }

  handleContabilSelect = () => {
    this.setState(prevState => ({ contabilSelect: !prevState.contabilSelect }));
  }

  handlePisicologicoSelect = () => {
    this.setState(prevState => ({ psicologicoSelect: !prevState.psicologicoSelect }));
  }

  handleSendPesquisa = (filiadosAComunicar) => {
    const { enqueueSnackbar } = this.props
    const { juridicoSelect, odontoSelect, contabilSelect, psicologicoSelect } = this.state;
    const clearStates = () => {
      this.setState({
        ...this.state,
        filiados: this.state.filiados.map(f => {
          if (f.tableData) {
            f.tableData.checked = false;
          }
          return f;
        }),
        juridicoSelect: false,
        odontoSelect: false,
        contabilSelect: false,
        psicologicoSelect: false
      })
    }

    const filiadosArray = filiadosAComunicar.filiadosAComunicar.length
    const selectedCount = [juridicoSelect, odontoSelect, contabilSelect, psicologicoSelect].filter(Boolean).length;
    if (filiadosArray < 1) {
      enqueueSnackbar("Você precisa selecionar pelo menos um(a) filiado(a)!", { variant: "error" });
      clearStates()
    } else if (selectedCount > 1) {
      enqueueSnackbar("Só é permitido o envio de uma pesquisa por vez!", { variant: "error" })
      clearStates()

    } else if (selectedCount < 1) {
      enqueueSnackbar("Você precisa selecionar pelo menos uma pesquisa!", { variant: "error" })
      clearStates()
    } else {
      this.props.enviarPesquisa(filiadosAComunicar);
      enqueueSnackbar("Pesquisa enviada com sucesso!", { variant: "success" })
      clearStates()

    }
  }

  render() {
    const { classes, loading } = this.props;
    const { juridicoSelect, odontoSelect, contabilSelect, psicologicoSelect, filiadosAComunicar } = this.state;

    const localization = tableI18n;
    localization.toolbar.nRowsSelected = `{0} Filiado(s) selecionado(s)`
    const filiados = this.props.filiados.filter(filiado => filiado.cadastro === "FILIADO")
    if (this.state.filiados.length === 0 && filiados.length > 0) {
      this.setState({ ...this.state, filiados })
    }

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Container maxWidth="xl">
          <Grid container item md={12} justifyContent="flex-end" spacing={2} className={classes.areaOperacoes}>
            <Grid item>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={
                  () => this.props.history.push(PROFILE)
                }
              >Voltar</Button>
            </Grid>
          </Grid>
          <Paper elevation={3} sx={{ padding: 3, mt: 4 }}>
            <Typography variant="h5" align="start" gutterBottom style={{ padding: 20 }}>
              Envio de Pesquisa de Satisfação
            </Typography>
            <Grid container spacing={2}>
              <Grid container item spacing={3} style={{ marginLeft: 5 }}>
                <Grid item xs={12} sm={2}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Button
                      className={classes.button}
                      color={`${juridicoSelect ? "primary" : "default"}`}
                      variant="contained"
                      onClick={() => this.handleJuridicoSelect()}
                    >
                      Jurídico
                    </Button>
                    <Button
                      className={classes.button}
                      color={`${odontoSelect ? "primary" : "default"}`}
                      variant="contained"
                      onClick={() => this.handleOdontoSelect()}>
                      Odonto
                    </Button>
                    <Button
                      className={classes.button}
                      color={`${contabilSelect ? "primary" : "default"}`}
                      variant="contained"
                      onClick={() => this.handleContabilSelect()}>
                      Contábil
                    </Button>
                    <Button
                      className={classes.button}
                      color={`${psicologicoSelect ? "primary" : "default"}`}
                      variant="contained"
                      onClick={() => this.handlePisicologicoSelect()}>
                      Psicológico
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MaterialTable
                    columns={[
                      { title: "Nome Completo", field: "nome_completo" },
                      { title: "Email", field: "email" },
                      { title: "CPF", field: "cpf" },
                      { title: "Sexo", field: "sexo" },
                      { title: "Casa", field: "empresa" },
                      { title: "UF", field: "estado" },
                    ]}
                    data={this.state.filiados}
                    title="Filiados"
                    localization={localization}
                    options={{
                      selection: true,
                      padding: 'dense'
                    }}
                    onSelectionChange={
                      (filiadosAComunicar) => {
                        this.setState({ filiadosAComunicar })
                      }
                    }
                  />
                  <Grid item container justifyContent="flex-end" style={{ padding: 20 }}>
                    <Button className={classes.button} color="primary" variant="contained"
                      onClick={() => {
                        const dados = {
                          juridicoSelect,
                          odontoSelect,
                          contabilSelect,
                          psicologicoSelect,
                          filiadosAComunicar: filiadosAComunicar.map(filiado => filiado.email),
                        };
                        this.handleSendPesquisa(dados)
                      }

                      }>Enviar</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>);

  }

}

const mapStateToProps = (
  { filiado: { filiados } }) => ({ filiados });

Satisfacao.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getFiliados, enviarPesquisa }
  ),
)(withRouter(withSnackbar(Satisfacao)));
