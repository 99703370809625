import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as routes from "../../constants/routes";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import * as roles from "../../constants/roles";
import { getFiliados } from "../../actions/filiado";
import tableI18n from "../TableLocalization";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import { downloadXlsx, calcularIdade } from "../commons/util";
import { formataData, dateFieldSort } from "../Financial/util";

class RelatorioFiliados extends React.Component {

  componentDidMount() {
    this.props.getFiliados()
  }

  render() {
    const { classes, filiados, authUser, loading } = this.props;
    const canExport = _.includes(authUser.roles, roles.GESTOR_USUARIO);
    filiados.forEach(item => { 
      item.data_cadastro = formataData(item.data_cadastro);
      item.data_desfiliacao = formataData(item.data_desfiliacao);
      item.idade = calcularIdade(item.data_nascimento);
     })

    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading && <Container maxWidth="xl">
          <Grid container sapacing={2} justifyContent="center" alignItems="center" className={classes.areaOperacoes}>
            <Grid container item md={12} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  className={classes.button}
                  color="default"
                  onClick={() => this.props.history.push(routes.RELATORIO_DEPENDENTES)}
                >Relatório de dependentes</Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.button}
                  color="default"
                  onClick={() => this.props.history.push(routes.FILIADOS)}
                >Filiados</Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={() => this.props.history.push(routes.DASHBOARD_FILIADOS)}
                >Dashboard</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item sm={12} sapacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={12}>
              <div className={classes.demo}>
                <MaterialTable
                  columns={[
                    { title: "Matrícula", field: "matricula" },
                    { title: "Nome Completo", field: "nome_completo" },
                    { title: "Email", field: "email" },
                    { title: "Cargo", field: "cargo" },
                    { title: "CPF", field: "cpf" },
                    { title: "Data de nascimento", field: "data_nascimento", customSort: (a, b) => dateFieldSort(a, b, 'data_nascimento') },
                    { title: "Idade", field: "idade"},
                    { title: "Sexo", field: "sexo" },
                    { title: "Casa", field: "empresa" },
                    { title: "CEP", field: "cep" },
                    { title: "Endereço", field: "logradouro" },
                    { title: "Bairro", field: "bairro" },
                    { title: "Cidade", field: "cidade" },
                    { title: "UF", field: "estado" },
                    { title: "Situação", field: "situacao_filiado" },
                    { title: "Nome do falecido", field: "nome_falecido" },
                    { title: "CPF do falecido", field: "cpf_falecido" },
                    { title: "Celular", field: "tel_celular" },
                    { title: "Tel. comercial", field: "tel_comercial" },
                    { title: "Tel. residencial", field: "tel_residencial" },
                    { title: "Forma de pagamento", field: "forma_pagamento" },
                    { title: "Data de cadastro", field: "data_cadastro", customSort: (a, b) => dateFieldSort(a, b, 'data_cadastro') },
                    { title: "Data de desfiliação", field: "data_desfiliacao", customSort: (a, b) => dateFieldSort(a, b, 'data_desfiliacao') },
                    { title: "Cadastro", field: "cadastro", lookup: { 'FILIADO': 'Filiado', 'INATIVO': 'Desfiliado' }, },
                  ]}
                  data={filiados}
                  title="Relatório de Filiados"
                  localization={tableI18n}
                  options={{
                    filtering: true,
                    exportButton: canExport,
                    exportAllData: true,
                    exportDelimiter: ';',
                    exportFileName: 'relatorio de filiados',
                    exportCsv: (columns, data) => {
                      downloadXlsx(columns, data, 'filiados.xlsx')
                    },
                    padding: 'dense'
                  }}
                  icons={{ Filter: () => (<span></span>) }}
                />
              </div>
            </Grid>
          </Grid>
        </Container>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ filiado: { filiados, loading } }) => ({ filiados, loading });


RelatorioFiliados.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getFiliados }
  ),
)(RelatorioFiliados);
