import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { formataData } from "../Financial/util";
import {
  getFiliados,
  setFiliadoId,
  setFiliado,
  saveFiliado,
  deleteFiliado,
} from "../../actions/filiado";
import * as roles from "../../constants/roles";
import * as routes from "../../constants/routes";
import tableI18n from "../TableLocalization";
import UserDetail from "../UserDetail";
import ConfirmDialog from "../commons/ConfirmDialog";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import DependentList from "../DependentList";
import { matchFullName, calcularIdade } from "../commons/util";
import ProcessoFiliadoList from "../ProcessoFiliadoList";
import { getFiliadosProcesso, getProcessos } from "../../actions/juridico";
import { downloadAnexo } from "../../firebase/storage";

class UserList extends React.Component {
  state = {
    open: false,
    dependentsOpen: false,
    processoOpen: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: "",
    filiadoId: null,
  };

  componentDidMount() {
    this.props.getFiliados();
    this.props.getProcessos();
    this.props.getFiliadosProcesso()
  }

  handleFiliadoClickOpen = () => {
    this.setState({ open: true });
  };

  handleFiliadoClose = (filiadoId) => {
    this.props.setFiliado(null)
    this.props.setFiliadoId(null)
    this.setState({ open: false });
    if (filiadoId) {
      this.reloadFiliados();
    }
  };

  handleDependentsOpen = () => {
    this.setState({ dependentsOpen: true });
  };

  handleDependentsClose = () => {
    this.setState({ dependentsOpen: false });
  };

  handleProcessosOpen = () => {
    this.props.getProcessos();
    this.setState({ processoOpen: true });
  };

  handleProcessosClose = () => {
    this.setState({ processoOpen: false });
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  reloadFiliados() {
    this.props.getFiliados();
  }

  render() {
    const { classes, filiados, authUser, loading } = this.props;
    const canEdit = _.includes(authUser.roles, roles.GESTOR_USUARIO);
    const canExport = _.includes(authUser.roles, roles.GESTOR_SISTEMA);
    const isEditor = _.includes(authUser.roles, roles.EDITOR_FILIADO);
    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading && (
          <Container maxWidth="xl">
            <Grid
              container
              sapacing={2}
              justifyContent="center"
              alignItems="center"
              className={classes.areaOperacoes}
            >
              <Grid
                container
                item
                md={12}
                justifyContent="flex-end"
                spacing={2}
              >
                <Grid item>
                  <Button
                    className={classes.button}
                    color="default"
                    onClick={() =>
                      this.props.history.push(routes.RELATORIO_DEPENDENTES)
                    }
                  >
                    Relatório de dependentes
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    color="default"
                    onClick={() =>
                      this.props.history.push(routes.FILIADOS_RELATORIO)
                    }
                  >
                    Relatório de filiados
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      this.props.history.push(routes.DASHBOARD_FILIADOS)
                    }
                  >
                    Dashboard
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" className={classes.title} />
              <div className={classes.demo}>
                <MaterialTable
                  columns={[
                    { title: "Matrícula", field: "matricula" },
                    {
                      title: "Nome Completo",
                      field: "nome_completo",
                      customFilterAndSearch: (term, row) =>
                        matchFullName(term, row.nome_completo),
                      render: (rowData) => {
                        return (
                          <span
                            style={
                              rowData.data_desfiliacao
                                ? { color: "#f44336" }
                                : {}
                            }
                          >
                            {rowData.nome_completo}
                          </span>
                        );
                      },
                    },
                    { title: "CPF", field: "cpf" },
                    { title: "Email", field: "email" },
                    { title: "Casa", field: "empresa" },
                    { title: "Situação", field: "situacao_filiado" },
                    { title: "Celular", field: "tel_celular" },
                    {
                      title: "Idade",
                      field: "data_nascimento",
                      render: (row) => calcularIdade(row.data_nascimento),
                    },
                    { title: "Filiação", field: "data_filiacao" },
                    {
                      title: "Desfiliação",
                      field: "data_desfiliacao",
                      render: (row) => formataData(row.data_desfiliacao),
                    },
                  ]}
                  data={filiados}
                  title="Filiados"
                  actions={[
                    {
                      icon: canEdit || isEditor ? "edit" : "info",
                      tooltip: canEdit || isEditor ? "Editar" : "Detalhe",
                      onClick: (event, row) =>
                        this.props.setFiliadoId(row.id) &&
                        this.handleFiliadoClickOpen(),
                    },
                    (row) => (
                      {
                        icon: "balance",
                        tooltip: "PROCESSOS",
                        iconProps: {
                          style: {
                            color: this.ifHasProcesso(row) ? 'green' : 'whitesmoke'
                          }
                        },
                        onClick: (event, row) =>
                          this.props.setFiliadoId(row.id) &&
                          this.handleProcessosOpen(row.id),
                      })
                    ,
                    {
                      icon: "supervisor_account",
                      tooltip: "Dependentes",
                      onClick: (event, row) =>
                        this.props.setFiliadoId(row.id) &&
                        this.handleDependentsOpen(),
                    },
                    canEdit
                      ? {
                        icon: "delete",
                        tooltip: "Excluir",
                        onClick: (event, row) =>
                          this.handleConfirmOpen(
                            () => this.props.deleteFiliado(row.id),
                            "Confirma a exclusão permanente do filiado?"
                          ),
                      }
                      : null,
                      (rowData) => {
                        if (!canEdit) {
                          return null;
                        } else {
                          return !rowData.data_desfiliacao
                            ? {
                                icon: "remove",
                                disabled: !canEdit,
                                iconProps: {
                                  color: "error", 
                                },
                                tooltip: "Remover filiação",
                                onClick: (event, row) => {
                                  this.handleConfirmOpen(
                                    () => this.removeFiliado(row),
                                    "Confirma exclusão do cadastro?"
                                  );
                                },
                              }
                            : {
                                icon: "add",
                                disabled: !canEdit,
                                iconProps: {
                                  color: "primary", 
                                },
                                tooltip: "Ativar", 
                                onClick: (event, row) => {
                                  rowData.data_desfiliacao = "";
                                  this.props.saveFiliado(row.id, {
                                    data_desfiliacao: rowData.data_desfiliacao,
                                  });
                                  this.reloadFiliados(); 
                                },
                              };
                        }
                      },
                    (rowData) =>
                      rowData.anexos ?
                        {
                          icon: 'attachment',
                          tooltip: 'Ver ficha cadastral',
                          onClick: (event, row) => {
                            const anexo = row.anexos.map(anexo => anexo.url)
                            downloadAnexo(anexo[0])
                          }
                        } : null
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    exportButton: canExport,
                  }}
                  localization={tableI18n}
                />
                <Fab
                  color="primary"
                  aria-label="Cadastrar"
                  className={classes.fab}
                  onClick={() =>
                    this.props.setFiliadoId(null) &&
                    this.props.setFiliado(null) &&
                    this.handleFiliadoClickOpen()
                  }
                >
                  <AddIcon />
                </Fab>
              </div>
            </Grid>
            <UserDetail
              open={this.state.open}
              handleClose={this.handleFiliadoClose}
              canEdit={canEdit || isEditor}
            />
            <DependentList
              open={this.state.dependentsOpen}
              handleClose={this.handleDependentsClose}
              canEdit={canEdit || isEditor}
            />
            <ConfirmDialog
              open={this.state.confirmOpen}
              message={this.state.confirmMessage}
              onClose={this.handleConfirmClose}
            />
            <ProcessoFiliadoList
              open={this.state.processoOpen}
              handleClose={this.handleProcessosClose}
              canEdit={canEdit || isEditor}
            />
          </Container>
        )}
      </React.Fragment>
    );
  }

  ifHasProcesso(row) {
    const { filiadosProcesso } = this.props;
    const filiadoId = row.id;
    let hasProcesso = false;
    if (filiadosProcesso && filiadosProcesso.length > 0) {
      for (let i = 0; i < filiadosProcesso.length; i++) {
        if (filiadosProcesso[i].id === filiadoId) {
          hasProcesso = true;
          break;
        }
      }
    }
    return hasProcesso
  }

  removeFiliado(row) {
    row.data_desfiliacao = new Date().getTime();
    this.props.saveFiliado(row.id, { data_desfiliacao: row.data_desfiliacao });
  }
}

const mapStateToProps = ({ filiado: { filiados, loading }, filiados_processos: { filiadosProcesso } }) => ({
  loading,
  filiados,
  filiadosProcesso
});

UserList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getFiliados,
    setFiliadoId,
    setFiliado,
    saveFiliado,
    deleteFiliado,
    getProcessos,
    getFiliadosProcesso,
  })
)(withRouter(UserList));
