import _ from "lodash";
import { reset } from "redux-form";
import { FILIADOS_SET, FILIADO_SET, FILIADO_ID_SET, HISTORICO_SET, FILIADOS_REQUEST, FILIADO_UPDATED, DELETE_FILIADO } from './types';
import { db } from "../firebase";

export const saveFiliado = (id, data) => {
     return dispatch => {
          db.doSaveFiliado(id, data).then(() => {
               dispatch({ type: FILIADO_UPDATED, payload: { id, data } });
               dispatch(reset('filiado'))
          });
     };
}

export const getFiliados = () => {
     return dispatch => {
          dispatch({ type: FILIADOS_REQUEST });
          db.onceGetFiliados().then((snapshot) => {
               let filiados = snapshot.val()
               Object.keys(filiados).map(id => {
                    filiados[id].cadastro = filiados[id].data_desfiliacao ? "INATIVO" : "FILIADO"
                    filiados[id].id = id
                    return filiados[id]
               });
               filiados = _.values(filiados);
               dispatch({ type: FILIADOS_SET, filiados });
          })
     }

}

export const getFiliado = (id) => {
     return dispatch => {
          db.onceGetFiliado(id).then((snapshot) => {
               const filiado = snapshot.val()
               dispatch({ type: FILIADO_SET, filiado })
          });
     }

}

export const deleteFiliado = (id) => {
     return dispatch => {
          db.doDeleteFiliado(id).then(() => {
               dispatch({ type: DELETE_FILIADO, payload: { id } });
          })
     }
}

export const setFiliado = (filiado) => dispatch => dispatch({ type: FILIADO_SET, filiado })

export const setFiliadoId = (filiadoId) => dispatch => dispatch({ type: FILIADO_ID_SET, filiadoId })

export const getHistorico = (id) => {
     return dispatch => {
          db.onceGetHistoricoFiliado(id).then(snapshot => {
               let historico = snapshot.val()
               if (historico) {
                    Object.keys(historico).map(key => (historico[key].data = parseInt(key)));
                    historico ? historico = _.values(historico).reverse() : historico = [];
                    dispatch({ type: HISTORICO_SET, historico });
               }
          });
     }
}

export const setHistorico = (historico) => dispatch => dispatch({ type: HISTORICO_SET, historico })