import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import tableI18n from "../TableLocalization";
import MaterialTable, { MTableToolbar } from "material-table";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { getSuprimentos, setSuprimentoId, setSuprimento, deleteSuprimento } from "../../actions/suprimentos"
import { getExpenses } from "../../actions/financial"
import RangeDatePicker from "../commons/RangeDatePicker";
import ConfirmDialog from "../commons/ConfirmDialog";
import SindilegisBar from "../commons/SindilegisBar";
import CustomButton from "../commons/CustomButton";
import SuprimentoForm from "./suprimento";
import styles from "./styles";
import { getDataInicial, getDataFinal, formatarValor, formataData, calcularEstorno, somadorDouble } from "../Financial/util";

class ListaSuprimentos extends React.Component {
  state = {
    open: false,
    fromDate: getDataInicial(),
    toDate: getDataFinal(),
    confirmOpen: false,
    confirmAction: Function
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    this.props.getExpenses();
    this.props.getSuprimentos();
  }

  handleConfirmOpen = (confirmAction) => {
    this.setState({ confirmOpen: true, confirmAction });
  };

  handleConfirmClose = confirm => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  editSuprimento(event, id) {
    this.props.setSuprimentoId(id);
    this.setState({ open: true });
    event.stopPropagation();
  }

  handleOpen() {
    this.props.setSuprimentoId(null);
    this.props.setSuprimento(null);
    this.setState({ open: true });
    this.props.getSuprimentos();
  }

  handleClose() {
    this.setState({ open: false, start: null, end: null });
    this.props.setSuprimentoId(null);
    this.props.setSuprimento(null);
    this.props.getSuprimentos();
  }

  handleDateChange(date) {
    this.setState(date)
  }

  getExpenses() {
    const { fromDate, toDate } = this.state;
    return this.props.expenses
      .filter(e => fromDate.getTime() <= e.data_vencimento && toDate.getTime() >= e.data_vencimento)
      .filter(e => e.meio_pagamento === 'Dinheiro')
  }

  getSuprimentos() {
    return this.props.suprimentos;
  }

  getResultado() {
    const somaDespesas = this.props.expenses
      .filter(e => e.meio_pagamento === 'Dinheiro')
      .map(e => calcularEstorno(e))
      .reduce(somadorDouble, 0);

    const somaEntradas = this.props.suprimentos.map(e => e.valor).reduce(somadorDouble, 0);
    return (somaEntradas - somaDespesas);
  }

  selecionarSuprimento(event, id) {
    const { filteredSuprimentos } = this.state;
    const index = filteredSuprimentos.findIndex(d => d.id === id);
    const despesa = { ...filteredSuprimentos[index], selecionado: !filteredSuprimentos[index].selecionado }
    filteredSuprimentos.splice(index, 1, despesa)
    this.setState({ filteredSuprimentos: [...filteredSuprimentos] })
    event.stopPropagation();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <SindilegisBar />
        <Container maxWidth="xl" className={classes.containerBottomPadding}>
          <Grid container justifyContent="space-between" alignItems="center" className={classes.areaOperacoes}>
            <Grid item container spacing={1} md={5} justifyContent="flex-start"  >
              <RangeDatePicker fromDate={this.state.fromDate} toDate={this.state.toDate} onChange={(data) => this.handleDateChange(data)} />
            </Grid>
            <Grid container item md={4} justifyContent="flex-end">
              <Button className={classes.button} color="primary" variant="contained"
                size="large" onClick={this.handleOpen}>
                CADASTRAR ENTRADA
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item md={7}>
              <MaterialTable
                columns={[
                  { title: 'Data', field: 'data_vencimento', render: (row) => <span>{formataData(row.data_vencimento)}</span> },
                  { title: 'Descrição', field: 'descricao' },
                  { title: 'Nº documento', field: 'numero_documento' },
                  { title: 'Valor', field: 'valor', render: (row) => <span>{formatarValor(row.valor)}</span> },
                ]}
                data={this.getExpenses()}
                title="Saídas"
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 20, 50, 100]
                }}
                localization={tableI18n}
                components={{
                  Toolbar: props => (
                    <div>
                      <MTableToolbar {...props} />
                      <div style={{ padding: '10px' }}>
                        Total: {formatarValor(props.renderData.map(e => calcularEstorno(e)).reduce(somadorDouble, 0))}
                      </div>
                    </div>
                  ),
                }}
              />
            </Grid>
            <Grid item md={5}>
              <MaterialTable
                columns={[
                  { title: 'Data', field: 'data', render: (row) => <span>{formataData(row.data)}</span> },
                  { title: 'Descrição', field: 'descricao' },
                  { title: 'Valor', field: 'valor', render: (row) => <span>{formatarValor(row.valor)}</span> },
                ]}
                data={this.props.suprimentos}
                title="Entradas"
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                }}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Editar",
                    onClick: (event, row) => this.editSuprimento(event, row.id)
                  },
                  rowData => {
                    return {
                      icon: "delete",
                      tooltip: "Excluir",
                      onClick: (event, row) => this.handleConfirmOpen(() => this.props.deleteSuprimento(row.id))
                    }
                  }
                ]}
                localization={tableI18n}
                components={{
                  Toolbar: props => (
                    <div>
                      <MTableToolbar {...props} />
                      <div style={{ padding: '10px' }}>
                        Total: {formatarValor(props.renderData.map(e => e.valor).reduce(somadorDouble, 0))}
                      </div>
                    </div>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={4}>
              {this.renderResultado()}
            </Grid>
          </Grid>
          <SuprimentoForm open={this.state.open} handleClose={this.handleClose} />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message="Confirma exclusão da entrada?"
            onClose={this.handleConfirmClose}
          />
        </Container>
      </div>
    );
  }

  renderResultado() {
    const resultado = this.getResultado();
    return <CustomButton color={resultado > 0 ? 'blue' : 'red'}>Saldo: {formatarValor(resultado)}</CustomButton>;
  }
}

const mapStateToProps = ({
  suprimento: { suprimentos, suprimentoId },
  financial: { expenses } }) => ({
    suprimentos,
    suprimentoId,
    expenses
  });

ListaSuprimentos.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getSuprimentos, setSuprimentoId, setSuprimento, deleteSuprimento, getExpenses }
  )
)(withRouter(ListaSuprimentos));
