import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from '@material-ui/core/Container';
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import * as roles from "../../constants/roles";
import * as routes from "../../constants/routes";
import { getProcessos } from "../../actions/juridico";
import tableI18n from "../TableLocalization";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import { downloadXlsx } from "../commons/util";
import { formataData, formatarValor } from "../Financial/util";

function converterParaNumber(valor) {
    return valor ? Number(valor.replaceAll(".", "").replaceAll(",", ".")) : 0;
  }

class RelatorioProcessos extends React.Component {

    componentDidMount() {
        this.props.getProcessos()
    }

    render() {
        const { classes, processos, authUser, loading } = this.props;
        const canExport = _.includes(authUser.roles, roles.GESTOR_USUARIO);
        
        processos.forEach(p => { 
            p.valor_causa = formatarValor(converterParaNumber(p.valorCausa))
            p.ano = p.dataProtocolo ? new Date(p.dataProtocolo).getFullYear() : ''
            p.filiadosNomes = _.size(p.filiados) > 0  ? p.filiados.map((f) => f.nome_completo).join(", ") : ''
            p.todosComentarios = _.size(p.comentarios) > 0  ? p.comentarios.map((c) => c.comentario).join(", ") : ''
            p.portal = p.publicarPortalSindilegis === undefined ? "" : p.publicarPortalSindilegis ? "S": "N"
            p.empresas = p.filiados ? 
                _.uniq(p.filiados.filter((f) => f.empresa != null && f.empresa.length > 0).map((f) => f.empresa)).join(", ")
                : ""
            p.nomeEscritorio = p.escritorio ? p.escritorio.nome : ""
        })
        return (
            <React.Fragment>
                <SindilegisBar />
                <Loading loading={loading} />
                {!loading && <Container maxWidth="xl">
                    <Grid container sapacing={2} justifyContent="center" alignItems="center" className={classes.areaOperacoes}>
                        <Grid container item md={12} justifyContent="flex-end" spacing={2}>
                            <Grid item>
                                <Button
                                    color="default"
                                    style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    }}
                                    variant="contained"
                                    onClick={() =>
                                    this.props.history.push(routes.OBJETO_PROCESSO)
                                    }
                                >
                                    Gerenciar objetos de processo
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    }}
                                    variant="contained"
                                    onClick={() =>
                                    this.props.history.push(routes.JURIDICO_PROCESSOS)
                                    }
                                >
                                    Gerenciar processos
                                </Button>
                            </Grid> 
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className={classes.demo}>
                            <MaterialTable
                                columns={[
                                    { title: "Tipo processo", field: "tipo" },
                                    { title: "Número processo", field: "numeroProcesso" },
                                    { title: "Ano", field: "ano" },
                                    { title: "Comentários", field: "todosComentarios" },
                                    { title: "Escritório", field: "nomeEscritorio" },
                                    { title: "filiados", field: "filiadosNomes" },
                                    { title: "Casa(s)", field: "empresas" },
                                    { title: "Tribunal/Orgão", field: "tribunalOrgao" },
                                    { title: "Situação", field: "situacao" },
                                    { title: "Valor", field: "valor_causa" },
                                    { title: "Portal", field: "portal" },
                                    {title: "Data", field: "dataProtocolo",
                                        render: (row)=>(
                                            <span>{formataData(row.dataProtocolo)}</span>
                                        ),
                                        hidden: true,
                                    },
                                    {
                                        title: "CPF Filiados",
                                        field: "filiados",
                                        render: (row) => {
                                          return row.filiados
                                           ? row.filiados.map((filiado) => filiado.cpf).join(", ") : false
                                        },
                                        hidden: true,
                                      }

                                    
                                ]}
                                data={processos}
                                title="Relatório de processos"
                                localization={tableI18n}
                                options={{
                                    filtering: true,
                                    exportButton: canExport,
                                    exportAllData: true,
                                    exportDelimiter: ';',
                                    exportFileName: 'relatorio de processos',
                                    exportCsv: (columns, data) => {
                                        const dadosFormatados = data.map(row=>({
                                            ...row,
                                            dataProtocolo:formataData(row.dataProtocolo),
                                            filiados: row.filiados ? row.filiados.map(filiado=> filiado.cpf.toString()).join(", "):''
                                        }))
                                        downloadXlsx(columns, dadosFormatados, 'processos.xlsx');
                                    },
                                    padding: 'dense'
                                }}
                                icons={{ Filter: () => (<span></span>) }}
                            />
                        </div>
                    </Grid>
                </Container>}
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({
    juridico: { processos, loading },
  }) => ({
    processos,
    loading,
  });

RelatorioProcessos.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        { getProcessos }
    ),
)(RelatorioProcessos);
