import React from "react";
import XLSX from "xlsx";
import { db } from "../../firebase";
import { differenceInYears } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import NumberFormat from "react-number-format";

const dateFns = new DateFnsUtils({ locale: ptLocale });

export const formataData = (data) => {
  if (data) {
    try {
      return dateFns.format(new Date(data), "dd/MM/yyyy");
    } catch (e) {
      console.error("Data inválida: ", data);
    }
  }
  return "";
};

export const formatarValor = (valor) => {
  return (
    <NumberFormat
      value={valor}
      displayType={"text"}
      isNumericString={true}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowNegative={true}
      fixedDecimalScale={true}
      prefix={"R$ "}
    />
  );
};

export function isDataAnterior(dt, dataAtual) {
  return (
    dt.getFullYear() < dataAtual.getFullYear() ||
    (dt.getFullYear() === dataAtual.getFullYear() &&
      dt.getMonth() < dataAtual.getMonth()) ||
    (dt.getDate() < dataAtual.getDate() &&
      dt.getMonth() === dataAtual.getMonth() &&
      dt.getFullYear() === dataAtual.getFullYear())
  );
}

export const downloadXlsx = (columns, data, filename) => {
  const wb = XLSX.utils.book_new();
  const ws = getWorkSheet(columns, data);
  XLSX.utils.book_append_sheet(wb, ws);
  XLSX.writeFile(wb, filename);
  logUser(filename);
};

export const downloadWorksheets = (sheets, filename) => {
  const wb = XLSX.utils.book_new();
  sheets.forEach((item) =>
    XLSX.utils.book_append_sheet(wb, item.ws, item.sheetName)
  );
  XLSX.writeFile(wb, filename);
  logUser(filename);
};

export const readXlsx = (e) => {
  const workbook = XLSX.read(e.target.result, {
    type: "buffer",
    cellDates: true,
  });
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  return XLSX.utils.sheet_to_json(sheet);
};

function logUser(filename) {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  db.doLogUser(authUser.uid, {
    type: "report_download",
    filename,
    date: new Date().getTime(),
  });
}

export function getWorkSheet(columns, data) {
  const fields = columns.map((c) => c.field);
  const header = columns.map((c) => c.title);

  const array = [header];

  data.forEach((d) => {
    const values = fields.map((field) => d[field]);
    array.push(values);
  });
  return XLSX.utils.aoa_to_sheet(array);
}

function normalize(str) {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

function compare(a, b) {
  return a && b && normalize(a).indexOf(normalize(b)) > -1;
}

export const matchFullName = (term, full_name) => {
  term = term.trim();
  if (term && term.length > 2 && full_name) {
    let splitedTerm = term.split(" ");
    const splitedName = full_name.split(" ");
    if (splitedTerm.length > 1) {
      return (
        compare(splitedName[0], splitedTerm[0]) &&
        splitedName.some((p) => compare(p, splitedTerm[1])) &&
        (!splitedTerm[2] || splitedName.some((p) => compare(p, splitedTerm[2])))
      );
    } else {
      return splitedName.some((p) => compare(p, term));
    }
  }
  return false;
};

export const calcularIdade = (data_nascimento) => {
  if (data_nascimento) {
    try {
      const ar = data_nascimento.split("/");
      return differenceInYears(new Date(), new Date(ar[2], ar[1] - 1, ar[0]));
    } catch (err) {
      console.error("Data inválida", data_nascimento, err);
    }
  }
};

export const validateEmail = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Email inválido'
    : undefined;
};
