export const estados = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"]

export const orgaoCargo = {
    'CAMARA': ['ANALISTA LEGISLATIVO', 'CONSULTOR', 'TÉCNICO LEGISLATIVO', 'AUXILIAR LEGISLATIVO', 'CNE', 'SECRETARIADO PARLAMENTAR (SP)'],
    'TCU': ['AUDITOR FEDERAL DE CONTROLE EXTERNO', 'TÉCNICO FEDERAL DE CONTROLE EXTERNO', 'AUXILIAR FEDERAL DE CONTROLE EXTERNO'],
    'SENADO': ['ADVOGADO', 'CONSULTOR', 'ANALISTA LEGISLATIVO', 'TÉCNICO LEGISLATIVO', 'AUXILIAR LEGISLATIVO', 'CARGO EM COMISSÃO'],
}

export const sexos = [
    'Feminino',
    'Masculino',
    'Não-binário'
];

export const pagamentos = [
    'Boleto',
    'Consignação'
]