import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { getInteracoes, setInteracaoId, setInteracao, saveInteracao, deleteInteracao } from "../../actions/interacao";
import { getFiliados, setFiliadoId } from "../../actions/filiado";
import { formataData } from "../Financial/util";
import tableI18n from "../TableLocalization";
import InteracaoForm from "./interacao";
import Importacao from "./importacao";
import * as routes from "../../constants/routes";
import ConfirmDialog from "../commons/ConfirmDialog";
import CustomMenu from "../commons/Menu";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";

class Interacoes extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
    openImportacao: false
  };

  componentDidMount() {
    this.props.getFiliados();
    this.props.getInteracoes();
  }

  handleInteracaoClickOpen = () => {
    this.setState({ open: true });
  };

  handleImportacaoClickOpen = () => {
    this.setState({ openImportacao: true });
  };

  handleInteracaoClose = reload => {
    this.setState({ open: false });
    this.props.setFiliadoId(null);
    this.props.setInteracao(null);
    this.props.setInteracaoId(null);
    if (reload) {
      this.reloadInteracoes();
    }
  };

  handleImportacaoClose = (reload) => {
    if (reload) {
      this.reloadInteracoes();
    }
    this.setState({ openImportacao: false });
  };


  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = confirm => {
    if (confirm) {
      this.state.confirmAction();
    }
    this.setState({ confirmOpen: false });
  };

  reloadInteracoes() {
    this.props.getInteracoes()
  }

  removeInteracao(id) {
    const { enqueueSnackbar } = this.props;
    this.props.deleteInteracao(id)
    enqueueSnackbar("Interação removida com sucesso!", {
      variant: "success"
    });
  }

  render() {
    const { classes, filiados, loading } = this.props;

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Paper className={classes.paper} elevation={1} hidden={loading}>
          <Grid container item md={12} justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                className={classes.button}
                color="default"
                onClick={() => this.props.history.push(routes.ITENS_INTERACAO)}
              >Itens de interação</Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                color="default"
                onClick={() => this.handleImportacaoClickOpen()}
              >Importar</Button>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  {
                    title: "Nome Completo",
                    field: "nome_completo",
                  }
                ]}
                data={filiados}
                title="Interações"
                actions={[
                  {
                    icon: "add",
                    tooltip: "Adicionar interação",
                    onClick: (event, row) =>
                      this.props.setFiliadoId(row.id) &&
                      this.handleInteracaoClickOpen()
                  }
                ]}
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100]
                }}
                localization={tableI18n}
                detailPanel={row => this.renderDetail(row)}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
              />
            </div>
          </Grid>
        </Paper>
        <InteracaoForm
          open={this.state.open}
          handleClose={this.handleInteracaoClose}
        />
        <Importacao
          open={this.state.openImportacao}
          handleClose={this.handleImportacaoClose}
        />
        <ConfirmDialog
          open={this.state.confirmOpen}
          message={this.state.confirmMessage}
          onClose={this.handleConfirmClose}
        />
      </div>
    );
  }

  renderDetail(filiado) {
    const { id } = filiado;
    const { classes, interacoes } = this.props;

    let itens = interacoes[id];
    if (itens) {
      return (
        <Grid container spacing={2} className={classes.detail}>
          {Object.keys(itens).map(key => {
            const item = itens[key];
            return (
              <Grid item sm={3} key={key}>
                <Card className={classes.root}>
                  <CardHeader
                    action={<CustomMenu options={[
                      {
                        label: 'Editar', action: () => this.props.setInteracaoId(`${id}/${key}`) &&
                          this.handleInteracaoClickOpen()
                      },
                      {
                        label: 'Excluir', action: () => this.handleConfirmOpen(
                          () => this.removeInteracao(`${id}/${key}`),
                          'Confirma a exclusão permanente da interação?'
                        )
                      }]} />
                    }
                    subheader={formataData(item.data)}
                  />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">Tipo: {item.tipo}</Typography>
                    <Typography variant="body2" color="textSecondary">Descrição: {item.descricao}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={1} className={classes.detail} alignItems="center" justifyContent="center">
          <Typography variant="body1">Nenhuma interação cadastrada</Typography>
        </Grid>
      )
    }
  }
}

const mapStateToProps = ({
  interacao: { interacoes },
  filiado: { filiados, loading }
}) => ({
  interacoes,
  filiados,
  loading
});

Interacoes.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getInteracoes, setInteracaoId, setInteracao, saveInteracao, deleteInteracao, getFiliados, setFiliadoId }
  ),
)(withRouter(withSnackbar(Interacoes)));
