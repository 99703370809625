import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValues } from "redux-form";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { db } from "../../firebase";
import InputText from "../commons/InputText";
import Select from "../commons/Select";
import Switch from "../commons/Switch";
import { cpfMask, cepMask, dateMask, celularMask, telefoneMask } from "../commons/masks";
import { withSnackbar } from "notistack";
import { changeUserEmail, createFiliado as createFiliadoFunction } from "../../firebase/functions";
import InputCep from "../commons/InputCep";
import styles from "./styles";
import { getFiliado, saveFiliado, setFiliado, setFiliadoId } from "../../actions/filiado";
import validate from "./validate";
import * as config from "./config";
import HistoricoFiliado from "./historico_filiado";
import AnexoFiliado from "./anexo_filiado"

class UserDetail extends React.Component {

  state = {
    loading: false,
    anexos: [],
  }

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.saveFiliado = this.saveFiliado.bind(this);
    this.onSucessCep = this.onSucessCep.bind(this);
  }

  componentWillReceiveProps({ filiadoId }) {
    if(filiadoId){
      this.props.getFiliado(filiadoId)
    }
  }


  handlePhoneKeyDown = e => {
    const { name, value } = e.target;
    if (e.keyCode === 8 && value.length === 11) {
      const newValue = value.slice(0, -1);
      this.props.change(name, newValue);
    }
  };

  async save(values) {
    const { enqueueSnackbar, filiadoId, filiado } = this.props;

    values.anexos = this.state.anexos

    this.setState({ loading: true })

    values.email = values.email.toLowerCase();

    if (filiadoId && filiado.email !== values.email) {
      await changeUserEmail(filiadoId, values.email)
        .then(() => {
          this.saveFiliado(filiadoId, values);
        })
        .catch(({ message }) => {
          this.setState({ loading: false })
          enqueueSnackbar("Erro ao alterar o email do usuario." + message, {
            variant: "error"
          });
        });
    } else {
      if (filiadoId) {
        this.saveFiliado(filiadoId, values);
      } else {
        createFiliadoFunction(values).then(() => {
          enqueueSnackbar("Usuário criado com sucesso!", {
            variant: "success"
          });
          this.setState({ loading: false, anexos: [] })
          this.props.handleClose(true);
        }).catch(error => {
          this.setState({ loading: false })
          enqueueSnackbar(error.message, { variant: "error" });
        });
      }
    }
  }

  saveFiliado(filiadoId, values) {
    const { enqueueSnackbar } = this.props;
    db.doSaveFiliado(filiadoId, values)
      .then(() => {
        enqueueSnackbar("Usuário atualizado com sucesso!", {
          variant: "success"
        });
        this.setState({ loading: false, anexos: [] })
        this.props.handleClose(filiadoId);
      })
      .catch(() =>
        this.setState({ loading: false }) &
        enqueueSnackbar("Erro ao atualizar os dados do usuário.", {
          variant: "error"
        })
      );
  }

  onSucessCep(data) {
    const { enqueueSnackbar } = this.props;
    const { uf, logradouro, bairro, localidade, erro } = data;
    if (erro) {
      enqueueSnackbar("CEP não encontrado.", {
        variant: "error"
      });
      return;
    }
    this.props.change('logradouro', logradouro)
    this.props.change('bairro', bairro)
    this.props.change('cidade', localidade)
    this.props.change('estado', uf)
  }

  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      reset,
      submitting,
      canEdit,
      filiado,
      open
    } = this.props;

    const { loading } = this.state;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        {filiado && filiado.data_desfiliacao && (
          <h4 style={{ color: "red", paddingLeft: "20px" }}>
            Atenção: servidor não é mais filiado.
          </h4>
        )}

        <form className={classes.container} onSubmit={handleSubmit(this.save)}>
          <DialogTitle id="form-dialog-title">Filiado</DialogTitle>
          {
            open &&
            <DialogContent style={{ minHeight: '50vh' }}>
              <Grid
                container
                item
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '50vh', display: loading ? 'block' : 'none' }}
              >
                <CircularProgress />
              </Grid>
              <Grid
                container
                spacing={2}
                style={loading ? { display: 'none' } : {}}
              >
                <Grid item sm={2}>
                  <Field name="matricula" component={InputText} label="Matrícula" />
                </Grid>
                <Grid item sm={8}>
                  <Field name="nome_completo" component={InputText} label="Nome" />
                </Grid>
                <Grid item sm={2}>
                  <Field name="cpf" component={InputText} label="CPF" {...cpfMask} />
                </Grid>
                <Grid item sm={2}>
                  <Field
                    name="data_nascimento"
                    component={InputText}
                    {...dateMask}
                    label="Nascimento"
                  />
                </Grid>
                <Grid item sm={2}>
                  <Field name="sexo" component={Select} label="Sexo">
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {config.sexos.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                  </Field>
                </Grid>
                <Grid item sm={4}>
                  <Field name="nome_mae" component={InputText} label="Nome da mãe" />
                </Grid>
                <Grid item sm={4}>
                  <Field name="email" component={InputText} label="E-mail" />
                </Grid>
                <Grid item container sm={12} spacing={2}>
                  <Grid item sm={2}>
                    <Field name="falecido" component={Switch} label="Falecido" />
                  </Grid>
                  <DadosFalecido />
                </Grid>
                <Grid item sm={6}>
                  <Field name="empresa" component={Select} label="Casa">
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    <MenuItem value={"SENADO"}>Senado</MenuItem>
                    <MenuItem value={"TCU"}>TCU</MenuItem>
                    <MenuItem value={"CAMARA"}>Câmara</MenuItem>
                  </Field>
                </Grid>
                <Grid item sm={6}>
                  <Cargo />
                </Grid>
                <Grid item sm={2}>
                  <Field name="cep" component={InputCep} {...cepMask} label="CEP" onSuccess={this.onSucessCep} />
                </Grid>
                <Grid item sm={4}>
                  <Field name="logradouro" component={InputText} label="Logradouro" />
                </Grid>
                <Grid item sm={3}>
                  <Field name="bairro" component={InputText} label="Bairro" />
                </Grid>
                <Grid item sm={2}>
                  <Field name="cidade" component={InputText} label="Cidade" />
                </Grid>
                <Grid item sm={1}>
                  <Field name="estado" component={Select} label="Estado">
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {config.estados.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                  </Field>
                </Grid>
                <Grid item sm={2}>
                  <Field name="situacao_filiado" component={Select} label="Situação">
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    <MenuItem value={"ATIVO"}>Ativo</MenuItem>
                    <MenuItem value={"INATIVO"}>Inativo</MenuItem>
                    <MenuItem value={"APOSENTADO"}>Aposentado</MenuItem>
                    <MenuItem value={"PENSIONISTA"}>Pensionista</MenuItem>
                  </Field>
                </Grid>
                <DadosPensionista />
                {<Grid item sm={3}>
                  <Field name="forma_pagamento" component={Select} label="Forma de pagamento">
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {config.pagamentos.map(p => <MenuItem key={p} value={p}>{p}</MenuItem>)}
                  </Field>
                </Grid>}
                <Grid item sm={3}>
                  <Field
                    name="data_filiacao"
                    component={InputText}
                    {...dateMask}
                    label="Filiado em"
                  />
                </Grid>
                <Grid item sm={2}>
                  <Field name="tel_celular" component={InputText} label="Celular" {...celularMask} onKeyDown={this.handlePhoneKeyDown} />
                </Grid>
                <Grid item sm={2}>
                  <Field name="tel_fixo" component={InputText} label="Telefone" {...telefoneMask} onKeyDown={this.handlePhoneKeyDown} />
                </Grid>
                <Grid item sm={12}>
                  <Field
                    name="observacao"
                    component={InputText}
                    label="Observação"
                    multiline={true}
                    rows={3}
                  />
                </Grid>
                <Grid item sm={12} style={{ paddingTop: 12 }}>
                  <AnexoFiliado
                    onChange={(anexos) => {
                      this.setState({ anexos: anexos }, () => {
                        this.props.change("anexosFlag", new Date().getTime());
                      });
                    }}
                    anexos={this.state.anexos || []}
                    filiadoId={this.props.filiadoId}
                    open={open}
                  />
                  <Field name="anexosFlag" component="input" type="hidden" />
                </Grid>
              </Grid>
              <HistoricoFiliado id={this.props.filiadoId} />
            </DialogContent>
          }
          <DialogActions>
            <Button
              onClick={() => reset() & this.setState({anexos: []}) & this.props.handleClose(null)}
              color="default"
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              disabled={!canEdit || pristine || submitting}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog >
    );
  }
}

const Cargo = formValues('empresa')(({ empresa }) => {
  let cargos = []
  if (empresa) {
    cargos = config.orgaoCargo[empresa];
  }

  return (
    <Field name="cargo" component={Select} label="Cargo">
      <MenuItem value="">
        <em>Selecione</em>
      </MenuItem>
      {cargos.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
    </Field>
  )
});

const DadosFalecido = formValues('falecido')(({ falecido }) => {
  if (falecido) {
    return (
      <React.Fragment>
        <Grid item sm={7}>
          <Field name="nome_titular" component={InputText} label="Nome do titular" />
        </Grid>
        <Grid item sm={3}>
          <Field name="cpf_titular" component={InputText} label="CPF do titular" {...cpfMask} />
        </Grid>
      </React.Fragment>
    )
  }
  return null
});

const DadosPensionista = formValues('situacao_filiado')(({ situacao_filiado }) => {
  if (situacao_filiado === 'PENSIONISTA') {
    return (
      <React.Fragment>
        <Grid item sm={7}>
          <Field name="nome_falecido" component={InputText} label="Nome do falecido" />
        </Grid>
        <Grid item sm={3}>
          <Field name="cpf_falecido" component={InputText} label="CPF do falecido" {...cpfMask} />
        </Grid>
      </React.Fragment>
    )
  }
  return null
});

const mapStateToProps = ({ filiado: { filiado, filiadoId } }) => ({
  filiado: filiado,
  filiadoId: filiadoId,
  initialValues: filiado
});

UserDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getFiliado, saveFiliado, setFiliado, setFiliadoId }
  )
)(
  reduxForm({
    form: "filiado",
    validate,
    enableReinitialize: true
  })(withSnackbar(UserDetail))
);
