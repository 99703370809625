import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { getItensInteracao, setItemInteracaoId, setItemInteracao, saveItemInteracao, deleteItemInteracao } from "../../actions/item_interacao";
import { getInteracoes } from "../../actions/interacao";
import tableI18n from "../TableLocalization";
import ItemInteracaoForm from "./item_interacao";
import ConfirmDialog from "../commons/ConfirmDialog";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import _ from "lodash";

class ItensInteracao extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: ''
  };

  componentDidMount() {
    this.props.getInteracoes();
    this.props.getItensInteracao();
  }

  handleItemInteracaoClickOpen = () => {
    this.setState({ open: true });
  };

  handleItemInteracaoClose = reload => {
    this.setState({ open: false });
    this.props.setItemInteracao(null);
    this.props.setItemInteracaoId(null);
    if (reload) {
      this.reloadItensItemInteracao();
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = confirm => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  reloadItensItemInteracao() {
    this.props.getItensInteracao()
  }

  render() {
    const { classes, itens_interacao, loading } = this.props;

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Paper className={classes.paper} elevation={1} hidden={loading}>
          <Grid container item md={12} justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                className={classes.button}
                color="primary"
                size="large"
                onClick={() => this.handleItemInteracaoClickOpen()}
              >Cadastrar item de interação</Button>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  { title: "Item de interação", field: "nome" },
                  { title: "Descrição", field: "descricao" }
                ]}
                data={itens_interacao}
                title="Itens de interação"
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Editar",
                    onClick: (event, row) =>
                      this.props.setItemInteracaoId(row.id) &&
                      this.handleItemInteracaoClickOpen()
                  },
                  {
                    icon: "delete",
                    tooltip: "Excluir",
                    onClick: (event, row) => this.handleConfirmOpen(
                      () => this.removeItemInteracao(row),
                      'Confirma a exclusão permanente do item de interação?'
                    )
                  },
                  row => !row.desabilitado ? {
                    icon: "block",
                    iconProps: {
                      color: "error"
                    },
                    tooltip: "Desabilitar",
                    onClick: (event, row) => {
                      this.handleConfirmOpen(() => this.desabilitarItemInteracao(row.id), 'Deseja desabilitar o item de interação?')
                    }
                  } : {
                      icon: "add",
                      iconProps: {
                        color: "primary"
                      },
                      tooltip: "Habilitar",
                      onClick: (event, row) => {
                        this.handleConfirmOpen(() => this.habilitarItemInteracao(row.id), 'Deseja habilitar o item de interação?')
                      }
                    }
                ]}
                options={{
                  actionsColumnIndex: -1
                }}
                localization={tableI18n}
              />
            </div>
          </Grid>
          <ItemInteracaoForm
            open={this.state.open}
            handleClose={this.handleItemInteracaoClose}
          />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Paper>
      </div>
    );
  }

  habilitarItemInteracao(id) {
    const { enqueueSnackbar } = this.props;
    this.props.saveItemInteracao(id, { desabilitado: false }, () => enqueueSnackbar("Item de interação habilitado com sucesso.", {
      variant: "success"
    }))
  }

  desabilitarItemInteracao(id) {
    const { enqueueSnackbar } = this.props;
    this.props.saveItemInteracao(id, { desabilitado: true }, () => enqueueSnackbar("Item de interação desabilitado com sucesso.", {
      variant: "success"
    }))
  }

  removeItemInteracao(item) {
    const { enqueueSnackbar, interacoes } = this.props;
    if (_.values(interacoes).some(e => e.item_interacao === item.nome)) {
      enqueueSnackbar("O item de interação está associado a uma ou mais interações e não pode ser excluído.", {
        variant: "error"
      })
      return;
    }

    this.props.deleteItemInteracao(item.id)
  }
}

const mapStateToProps = ({ item_interacao: { itens_interacao }, interacao: { interacoes, loading } }) => ({ itens_interacao, loading, interacoes });

ItensInteracao.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getItensInteracao, setItemInteracaoId, setItemInteracao, saveItemInteracao, deleteItemInteracao, getInteracoes }
  ),
)(withRouter(withSnackbar(ItensInteracao)));
