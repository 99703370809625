import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { getItensDespesa, setItemDespesaId, setItemDespesa, saveItemDespesa, deleteItemDespesa } from "../../actions/item_despesa";
import { getExpenses } from "../../actions/financial";
import tableI18n from "../TableLocalization";
import ItemDespesaForm from "./item_despesa";
import ConfirmDialog from "../commons/ConfirmDialog";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";

class ItensItemDespesa extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: ''
  };

  componentDidMount() {
    this.props.getExpenses();
    this.props.getItensDespesa();
  }

  handleItemDespesaClickOpen = () => {
    this.setState({ open: true });
  };

  handleItemDespesaClose = reload => {
    this.setState({ open: false });
    this.props.setItemDespesa(null);
    this.props.setItemDespesaId(null);
    if (reload) {
      this.reloadItensItemDespesa();
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = confirm => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  reloadItensItemDespesa() {
    this.props.getItensDespesa()
  }

  render() {
    const { classes, itens_despesa, loading } = this.props;

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Paper className={classes.paper} elevation={1} hidden={loading}>
          <Grid container item md={12} justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                className={classes.button}
                color="primary"
                size="large"
                onClick={() => this.handleItemDespesaClickOpen()}
              >Cadastrar item de despesa</Button>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  { title: "Tipo", field: "tipo" },
                  { title: "Item de despesa", field: "nome" },
                  { title: "Centro de custo", field: "centro_custo" },
                  { title: "Descrição", field: "descricao" }
                ]}
                data={itens_despesa}
                title="Itens de despesa"
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Editar",
                    onClick: (event, row) =>
                      this.props.setItemDespesaId(row.id) &&
                      this.handleItemDespesaClickOpen()
                  },
                  {
                    icon: "delete",
                    tooltip: "Excluir",
                    onClick: (event, row) => this.handleConfirmOpen(
                      () => this.removeItemDespesa(row),
                      'Confirma a exclusão permanente do item de despesa?'
                    )
                  },
                  row => !row.desabilitado ? {
                    icon: "block",
                    iconProps: {
                      color: "error"
                    },
                    tooltip: "Desabilitar",
                    onClick: (event, row) => {
                      this.handleConfirmOpen(() => this.desabilitarItemDespesa(row.id), 'Deseja desabilitar o item de despesa?')
                    }
                  } : {
                      icon: "add",
                      iconProps: {
                        color: "primary"
                      },
                      tooltip: "Habilitar",
                      onClick: (event, row) => {
                        this.handleConfirmOpen(() => this.habilitarItemDespesa(row.id), 'Deseja habilitar o item de despesa?')
                      }
                    }
                ]}
                options={{
                  actionsColumnIndex: -1
                }}
                localization={tableI18n}
              />
            </div>
          </Grid>
          <ItemDespesaForm
            open={this.state.open}
            handleClose={this.handleItemDespesaClose}
          />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Paper>
      </div>
    );
  }

  habilitarItemDespesa(id) {
    const { enqueueSnackbar } = this.props;
    this.props.saveItemDespesa(id, { desabilitado: false }, () => enqueueSnackbar("Item de despesa habilitado com sucesso.", {
      variant: "success"
    }))
  }

  desabilitarItemDespesa(id) {
    const { enqueueSnackbar } = this.props;
    this.props.saveItemDespesa(id, { desabilitado: true }, () => enqueueSnackbar("Item de despesa desabilitado com sucesso.", {
      variant: "success"
    }))
  }

  removeItemDespesa(item) {
    const { enqueueSnackbar, expenses } = this.props;
    if (expenses.some(e => e.item_despesa === item.nome)) {
      enqueueSnackbar("O item de despesa está associado a uma ou mais despesas e não pode ser excluído.", {
        variant: "error"
      })
      return;
    }

    this.props.deleteItemDespesa(item.id)
  }
}

const mapStateToProps = ({ item_despesa: { itens_despesa }, financial: { expenses, loading } }) => ({ itens_despesa, loading, expenses });

ItensItemDespesa.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getItensDespesa, setItemDespesaId, setItemDespesa, saveItemDespesa, deleteItemDespesa, getExpenses }
  ),
)(withRouter(withSnackbar(ItensItemDespesa)));
