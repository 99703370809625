export const partidos = [
  { sigla: "MDB", nome: "MOVIMENTO DEMOCRÁTICO BRASILEIRO" },
  { sigla: "PDT", nome: "PARTIDO DEMOCRÁTICO TRABALHISTA" },
  { sigla: "PT", nome: "PARTIDO DOS TRABALHADORES" },
  { sigla: "PCdoB", nome: "PARTIDO COMUNISTA DO BRASIL" },
  { sigla: "PSB", nome: "PARTIDO SOCIALISTA BRASILEIRO" },
  { sigla: "PSDB", nome: "PARTIDO DA SOCIAL DEMOCRACIA BRASILEIRA" },
  { sigla: "AGIR", nome: "AGIR" },
  { sigla: "MOBILIZA", nome: "Mobilização Nacional" },
  { sigla: "CIDADANIA", nome: "CIDADANIA" },
  { sigla: "PV", nome: "PARTIDO VERDE" },
  { sigla: "AVANTE", nome: "AVANTE" },
  { sigla: "PP", nome: "PROGRESSISTAS" },
  { sigla: "PSTU", nome: "PARTIDO SOCIALISTA DOS TRABALHADORES UNIFICADO" },
  { sigla: "PCB", nome: "PARTIDO COMUNISTA BRASILEIRO" },
  { sigla: "PRTB", nome: "PARTIDO RENOVADOR TRABALHISTA BRASILEIRO" },
  { sigla: "DC", nome: "DEMOCRACIA CRISTÃ" },
  { sigla: "PCO", nome: "PARTIDO DA CAUSA OPERÁRIA" },
  { sigla: "PODE", nome: "PODEMOS" },
  { sigla: "REPUBLICANOS", nome: "REPUBLICANOS" },
  { sigla: "PSOL", nome: "PARTIDO SOCIALISMO E LIBERDADE" },
  { sigla: "PL", nome: "PARTIDO LIBERAL" },
  { sigla: "PSD", nome: "PARTIDO SOCIAL DEMOCRÁTICO" },
  { sigla: "SOLIDARIEDADE", nome: "SOLIDARIEDADE" },
  { sigla: "NOVO", nome: "PARTIDO NOVO" },
  { sigla: "REDE", nome: "REDE SUSTENTABILIDADE" },
  { sigla: "PMB", nome: "PARTIDO DA MULHER BRASILEIRA" },
  { sigla: "UP", nome: "UNIDADE POPULAR" },
  { sigla: "UNIÃO", nome: "UNIÃO BRASIL" },
  { sigla: "PRD", nome: "PARTIDO RENOVAÇÃO DEMOCRÁTICA" },
  { sigla: "SEM PARTIDO", nome: "SEM PARTIDO" }
];

export const simNao = [
  'Sim',
  'Não'
]

export const religiao = [
  "Cristã",
  "Não tem religião",
  "Espírita",
  "Ubanda, Candomblé ou outras religiões afro-brasileiras",
  "Ateu",
  "Judaica",
  "Outras"
]

export const cargosETempo = [
  { name: 'cargo', label: 'Cargo' },
  { name: 'tempo', label: 'Tempo de Mandato' },
  {name: 'colegio_eleitoral', label:'Colégio Eleitoral'},
  { name: 'atual', label: 'Cargo Atual', type: 'checkbox' },

];
