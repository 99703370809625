import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import tableI18n from "../../TableLocalization";
import ConfirmDialog from "../../commons/ConfirmDialog";
import SindilegisBar from "../../commons/SindilegisBar";
import styles from "../styles";
import Loading from "../../commons/Loading";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ProcessoAdministrativoForm from "./processoAdministrativo";
import ProcessoJudicialForm from "./processoJudicial";
import ProcedimentoForm from "./procedimento";
import EventoProcessoForm from "./eventoProcesso";
import ManifestacaoForm from "./manifestacao";
import ComentarioProcessoForm from "./comentarioProcesso";
import FiliadoPopover from "../filiadoPopover";
import {
  formataData,
  formataDataHora,
  formatarValor,
} from "../../Financial/util";
import CardContent from "@material-ui/core/CardContent";
import {
  encerrarProcesso,
  reabrirProcesso,
  enviarParaManifestacaoSindilegis,
  excluirProcesso,
  getProcessos,
  setEscritorioAdvocacia,
  setEscritorioAdvocaciaId,
  setProcesso,
  deleteEventoProcesso,
  SITUACAO_PROCESSO_EM_ANDAMENTO,
  SITUACAO_PROCESSO_ENCERRADO,
  SITUACAO_PROCESSO_EXCLUIDO,
  TIPO_PROCESSO_ADMINISTRATIVO,
  TIPO_PROCESSO_JUDICIAL,
  TIPO_PROCESSO_PROCEDIMENTO,
} from "../../../actions/juridico";
import { getUser } from "../../../actions/user";
import _ from "lodash";
import * as roles from "../../../constants/roles";
import { downloadXlsx, matchFullName } from "../../commons/util";
import AnexoEvento from "./anexo_evento";
import { Button } from "@material-ui/core";
import { JURIDICO } from "../../../constants/routes";
import CustomMenu from "../../commons/Menu";

function converterParaNumber(valor) {
  return valor ? Number(valor.replaceAll(".", "").replaceAll(",", ".")) : 0;
}

class Processos extends React.Component {
  state = {
    open: false,
    judicialOpen: false,
    procedimentoOpen: false,
    confirmOpen: false,
    eventoOpen: false,
    comentarioOpen: false,
    manifestacaoOpen: false,
    confirmAction: Function,
    confirmMessage: "",
    processoId: null,
    eventoId: null,
    evento: null,
  };

  componentDidMount() {
    this.props.getProcessos();
    if (this.props.authUser) {
      this.props.getUser(this.props.authUser.uid);
    }
  }

  handleAtendimentoClickOpen = () => {
    this.setState({ open: true });
  };

  handleConclusaoAtendimentoClickOpen = () => {
    this.setState({ conclusaoOpen: true });
  };

  handleProcessoAdministrativoClose = (reload) => {
    this.setState({ open: false });
    this.props.setProcesso(null);
    if (reload) {
      this.props.getProcessos();
    }
  };

  handleInteracaoJudicialClose = (reload) => {
    this.setState({ judicialOpen: false });
    if (reload) {
      this.props.getProcessos();
    }
  };

  handleProcedimentoClose = (reload) => {
    this.setState({ procedimentoOpen: false });
    if (reload) {
      this.props.getProcessos();
    }
  };

  handleEventoClose = (reload) => {
    this.setState({ eventoOpen: false, evento:null, processoId: null, eventoId: null});
    if (reload) {
      this.props.getProcessos();
    }
  };

  handleComentarioClose = (reload) => {
    this.setState({ comentarioOpen: false });
    if (reload) {
      this.props.getProcessos();
    }
  };

  handleModalClose = (field, reload) => {
    this.setState({ [field]: false });
    if (reload) {
      this.props.getProcessos();
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
    }
    this.setState({ confirmOpen: false });
  };

  encerrarProcesso(processo) {
    const { enqueueSnackbar } = this.props;
    this.props.encerrarProcesso(processo);
    enqueueSnackbar("Processo encerrado com sucesso!", {
      variant: "success",
    });
  }

  reabrirProcesso(processo) {
    const { enqueueSnackbar } = this.props;
    this.props.reabrirProcesso(processo);
    enqueueSnackbar("Processo reaberto com sucesso!", {
      variant: "success",
    });
  }

  enviarParaManifestacaoSindilegis(processo) {
    const { enqueueSnackbar } = this.props;
    this.props.enviarParaManifestacaoSindilegis(processo);
    enqueueSnackbar(
      "Processo enviado para manifestação do Sindilegis com sucesso!",
      {
        variant: "success",
      }
    );
  }

  excluirProcesso(processo) {
    const { enqueueSnackbar } = this.props;
    this.props.excluirProcesso(processo);
    enqueueSnackbar("Processo excluído com sucesso!", {
      variant: "success",
    });
  }

  editarProcesso(processo) {
    const { setProcesso } = this.props;
    setProcesso(processo);

    if (processo.tipo === TIPO_PROCESSO_JUDICIAL) {
      this.setState({ ...this.state, judicialOpen: true });
    } else if (processo.tipo === TIPO_PROCESSO_ADMINISTRATIVO) {
      this.setState({ ...this.state, open: true });
    } else if (processo.tipo === TIPO_PROCESSO_PROCEDIMENTO) {
      this.setState({ ...this.state, procedimentoOpen: true });
    }
  }

  incluirEventoProcesso(processo) {
    const { setProcesso } = this.props;
    setProcesso(processo);

    this.setState({ ...this.state, eventoOpen: true });
  }

  handleDeleteEvento(id, processoId){
    const {enqueueSnackbar} = this.props;
    this.props.deleteEventoProcesso(id, processoId)
    enqueueSnackbar("Evento excluído com sucesso!", {variant: "success"})
    this.props.getProcessos()
  }

  handleEditEvento(processoId, eventoId, evento, anexos){
    this.setState({...this.state, eventoOpen:true, processoId, eventoId, evento, anexos})
  }

  incluirComentarioProcesso(processo) {
    const { setProcesso } = this.props;
    setProcesso(processo);

    this.setState({ ...this.state, comentarioOpen: true });
  }

  handleModalOpen(field, processo) {
    const { setProcesso } = this.props;
    setProcesso(processo);
    this.setState({ ...this.state, [field]: true });
  }

  renderNomeOrgaoTypography(nomeOrgao) {
    return _.isString(nomeOrgao) ? (
      <Typography key={nomeOrgao}>{nomeOrgao}</Typography>
    ) : null;
  }

  useQuery(){
    const {location} = this.props;
    return new URLSearchParams(location.search);
  }

  render() {
    const query = this.useQuery();
    let situacao = query.get("situacao");
    const fromDate = query.get("fromDate");
    const toDate = query.get("toDate")

    const {
      classes,
      processos,
      filiados,
      loading,
      filiadoLoading,
      authUser,
      user,
    } = this.props;
    const {processoId, eventoId, evento} = this.state;
    const isJuridico = _.includes(authUser.roles, roles.JURIDICO);
    const isVisualizadorJuridico = _.includes(
      authUser.roles,
      roles.VISUALIZADOR_JURIDICO
    );
    let processosFormatados = [];
    if (processos) {
      processosFormatados = processos
        .filter((p) => p.situacao !== SITUACAO_PROCESSO_EXCLUIDO)
        .filter(
          (p)=>
            situacao == null ||
            this.filterByQueryparam(p, situacao, fromDate, toDate)
        )
        .map((p) => ({
          ...p,
          filiados: p.filiados
            ? p.filiados.filter((f) => f.nome_completo !== null)
            : p.orgaos || ["Indeterminados"],

          filiadosNomes:
            _.size(p.filiados) > 0
              ? p.filiados.map((f) => f.nome_completo).join(", ")
              : "",
          temDesfiliados: p.filiados
            ? _.size(
                p.filiados.filter((filiadoOuOrgao) => {
                  const filiado = filiados.find(
                    (it) => it.id === filiadoOuOrgao.id
                  );
                  return filiado && filiado.data_desfiliacao ? true : false;
                })
              ) > 0
              ? "DESFILIADO"
              : "FILIADO"
            : null,
          empresas: p.filiados
            ? _.uniq(
                p.filiados
                  .filter((f) => f.empresa != null && f.empresa.length > 0)
                  .map((f) => f.empresa)
              ).join(", ")
            : "",
          ano: p.dataProtocolo ? new Date(p.dataProtocolo).getFullYear() : "",
          valorCausa: p.valorCausa,
          eventos: p.eventos,
          comentarios: p.comentarios
            ? p.comentarios.map((p) => p.comentario).join(", ")
            : "",
          nomeEscritorio: p.escritorio ? p.escritorio.nome : "",
          portal:
            p.publicarPortalSindilegis === undefined
              ? ""
              : p.publicarPortalSindilegis
              ? "S"
              : "N",
        }));
    }

    if (!isJuridico && !isVisualizadorJuridico) {
      processosFormatados = processosFormatados.filter((p) =>
        user.escritorios?.includes(p.escritorio.id)
      );
    }

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading && filiadoLoading} />
        <Card
          className={classes.paper}
          elevation={1}
          hidden={loading && filiadoLoading}
        >
          <CardHeader title="Processos" />
          <CardContent>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" className={classes.title} />
              <div className={classes.demo}>
                <Grid
                  container
                  item
                  md={12}
                  justifyContent="flex-end"
                  spacing={2}
                  className={classes.areaOperacoes}
                >
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                    this.props.history.push(JURIDICO)
                    }
                  >
                    Voltar
                  </Button>
                </Grid>
                <MaterialTable
                  columns={[
                    {
                      title: "Tipo processo",
                      field: "tipo",
                    },
                    {
                      title: "Escritório",
                      field: "nomeEscritorio",
                    },
                    {
                      title: "Filiados",
                      field: "filiadosNomes",
                      hidden: true,
                    },
                    {
                      noExport: true,
                      title: "Filiados",
                      field: "filiados",
                      customFilterAndSearch: (term, row) => {
                        return row.filiados
                          ? row.filiados
                              .filter((f) => f?.nome_completo)
                              .some(
                                (f) =>
                                  matchFullName(term, f.nome_completo) ||
                                  f.cpf.indexOf(term) > -1
                              )
                          : false;
                      },
                      render: (row) => {
                        return row.filiados
                          ? row.filiados.map((filiadoOuOrgao) => {
                              const filiado = filiados.find(
                                (it) => it.id === filiadoOuOrgao.id
                              );
                              return filiado ? (
                                <FiliadoPopover
                                  filiado={{ ...filiado, ...filiadoOuOrgao }}
                                  key={filiado.id}
                                />
                              ) : (
                                this.renderNomeOrgaoTypography(filiadoOuOrgao)
                              );
                            })
                          : null;
                      },
                    },
                    {
                      title: "Cadastro filiados",
                      field: "temDesfiliados",
                    },
                    {
                      title: "Comentários",
                      field: "comentarios",
                    },
                    {
                      title: "Casa(s)",
                      field: "empresas",
                    },
                    {
                      title: "Número processo",
                      field: "numeroProcesso",
                    },
                    {
                      title: "Ano",
                      field: "ano",
                      hidden: true,
                    },
                    {
                      title: "Data",
                      field: "dataProtocolo",
                      render: (row) => (
                        <span>{formataData(row.dataProtocolo)}</span>
                      ),
                    },
                    {
                      title: "Tribunal/Orgão",
                      field: "tribunalOrgao",
                    },
                    {
                      title: "Situação",
                      field: "situacao",
                    },
                    {
                      title: "Valor",
                      field: "valorCausa",
                      render: (row) => (
                        <span>
                          {formatarValor(converterParaNumber(row.valorCausa))}
                        </span>
                      ),
                      customSort: (a, b) =>
                        converterParaNumber(a.valorCausa) -
                        converterParaNumber(b.valorCausa),
                    },
                    {
                      title: "Portal",
                      field: "portal",
                    },
                  ]}
                  data={processosFormatados}
                  title={situacao || "Gerenciar processos"}
                  actions={
                    isVisualizadorJuridico
                      ? null
                      : [
                          (processo) => {
                            if (processo.linkProcesso) {
                              return {
                                icon: "link",
                                tooltip:
                                  "Ir para processo em: " +
                                  processo.linkProcesso,
                                onClick: (event, processo) =>
                                  window.open(processo.linkProcesso),
                              };
                            } else {
                              return null;
                            }
                          },
                          (processo) => {
                            if (
                              processo.situacao ===
                              SITUACAO_PROCESSO_EM_ANDAMENTO
                            ) {
                              return {
                                icon: "forward",
                                tooltip:
                                  "Enviar para manifestação do Sindilegis",
                                onClick: (event, processoFormatado) =>
                                  this.handleConfirmOpen(
                                    () =>
                                      this.enviarParaManifestacaoSindilegis(
                                        this.props.processos.find(
                                          (p) => processoFormatado.id === p.id
                                        )
                                      ),
                                    `Confirma o envio processo ${processoFormatado.numeroProcesso} do tipo ${processoFormatado.tipo} para manifestação do Sindilegis?`
                                  ),
                              };
                            } else {
                              return null;
                            }
                          },
                          (processo) => {
                            if (
                              isJuridico &&
                              processo.situacao ===
                                SITUACAO_PROCESSO_EM_ANDAMENTO
                            ) {
                              return {
                                icon: "edit",
                                tooltip: "Editar processo",
                                onClick: (event, processoFormatado) =>
                                  this.editarProcesso(
                                    this.props.processos.find(
                                      (p) => processoFormatado.id === p.id
                                    )
                                  ),
                              };
                            } else {
                              return null;
                            }
                          },
                          (processo) => {
                            if (
                              isJuridico &&
                              processo.situacao ===
                                SITUACAO_PROCESSO_EM_ANDAMENTO
                            ) {
                              return {
                                icon: "check",
                                tooltip: "Encerrar processo",
                                onClick: (event, processoFormatado) =>
                                  this.handleConfirmOpen(
                                    () =>
                                      this.encerrarProcesso(
                                        this.props.processos.find(
                                          (p) => processoFormatado.id === p.id
                                        )
                                      ),
                                    `Confirma o encerramento do processo ${processoFormatado.numeroProcesso} do tipo ${processoFormatado.tipo}?`
                                  ),
                              };
                            } else {
                              return null;
                            }
                          },
                          (processo) => {
                            if (
                              isJuridico &&
                              processo.situacao === SITUACAO_PROCESSO_ENCERRADO
                            ) {
                              return {
                                icon: "check",
                                tooltip: "Reabrir processo",
                                onClick: (event, processoFormatado) =>
                                  this.handleConfirmOpen(
                                    () =>
                                      this.reabrirProcesso(
                                        this.props.processos.find(
                                          (p) => processoFormatado.id === p.id
                                        )
                                      ),
                                    `Confirma a reabertura do processo ${processoFormatado.numeroProcesso} do tipo ${processoFormatado.tipo}?`
                                  ),
                              };
                            } else {
                              return null;
                            }
                          },
                          (processo) => {
                            if (
                              isJuridico &&
                              processo.situacao ===
                                SITUACAO_PROCESSO_EM_ANDAMENTO &&
                              isJuridico
                            ) {
                              return {
                                icon: "close",
                                tooltip: "Excluir processo",
                                onClick: (event, processoFormatado) =>
                                this.handleConfirmOpen(
                                  () => {
                                    this.excluirProcesso(
                                      this.props.processos.find(
                                        (p) => processoFormatado.id === p.id
                                      )
                                    );
                                  },
                                  `Confirma a exclusão do processo ${processoFormatado.numeroProcesso} do tipo ${processoFormatado.tipo}?`
                                )
                              };
                            } else {
                              return null;
                            }
                          },
                          () => {
                            return {
                              icon: "speaker_notes",
                              tooltip: "Adicionar evento",
                              onClick: (_, processoFormatado) =>
                                this.incluirEventoProcesso(
                                  this.props.processos.find(
                                    (p) => processoFormatado.id === p.id
                                  )
                                ),
                            };
                          },
                          () => {
                            return {
                              icon: "comment",
                              tooltip: "Adicionar comentário",
                              onClick: (_, processoFormatado) =>
                                this.incluirComentarioProcesso(
                                  this.props.processos.find(
                                    (p) => processoFormatado.id === p.id
                                  )
                                ),
                            };
                          },
                          () => {
                            return {
                              icon: "pending_actions",
                              tooltip: "Adicionar manifestação",
                              onClick: (_, processo) =>
                                this.handleModalOpen(
                                  "manifestacaoOpen",
                                  this.props.processos.find(
                                    (p) => processo.id === p.id
                                  )
                                ),
                            };
                          },
                        ]
                  }
                  options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    exportButton: true,
                    exportAllData: true,
                    exportCsv: (columns, data) => {
                      data.forEach((d) => {
                        d.data = formataData(d.data);
                      });

                      downloadXlsx(
                        _.filter(columns, (item) => !item.noExport),
                        data,
                        "processos.xlsx"
                      );
                    },
                  }}
                  localization={tableI18n}
                  detailPanel={(row) => this.renderDetail(row)}
                  onRowClick={(event, rowData, togglePanel) => togglePanel()}
                />
              </div>
            </Grid>
          </CardContent>
        </Card>

        <ProcessoAdministrativoForm
          open={this.state.open}
          handleClose={this.handleProcessoAdministrativoClose}
          authUser={authUser}
        />
        <ProcessoJudicialForm
          open={this.state.judicialOpen}
          handleClose={this.handleInteracaoJudicialClose}
          authUser={authUser}
        />
        <ProcedimentoForm
          open={this.state.procedimentoOpen}
          handleClose={this.handleProcedimentoClose}
          authUser={authUser}
        />
        <EventoProcessoForm
          open={this.state.eventoOpen}
          handleClose={this.handleEventoClose}
          processoId={processoId}
          eventoId={eventoId}
          evento={evento}
          authUser={authUser}
        />
        <ComentarioProcessoForm
          open={this.state.comentarioOpen}
          handleClose={this.handleComentarioClose}
        />
        <ManifestacaoForm
          open={this.state.manifestacaoOpen}
          handleClose={this.handleModalClose}
        />
        <ConfirmDialog
          open={this.state.confirmOpen}
          message={this.state.confirmMessage}
          onClose={this.handleConfirmClose}
        />
      </div>
    );
  }

  filterByQueryparam(p, situacao, fromDate, toDate) {
    if (this.props.location && this.props.location.search) {
      const dataProcesso = new Date(p.dataProtocolo);
      return (
        p.situacao === situacao &&
        dataProcesso >= fromDate &&
        dataProcesso <= toDate
      );
    } else {
      console.error("Erro: props.location.search é falso");
      return false;
    }
  }

  renderDetail(processo) {
    const { classes } = this.props;
    const { eventos, manifestacoes } = processo;

    return (
      <React.Fragment>
        {eventos ? (
          this.renderEventos(eventos, classes, processo.id)
        ) : (
          <Grid
            container
            spacing={1}
            className={classes.detail}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1">Nenhum evento cadastrado</Typography>
          </Grid>
        )}
        {manifestacoes ? (
          this.renderManifestacoes(manifestacoes, classes)
        ) : (
          <Grid
            container
            spacing={1}
            className={classes.detail}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1">Nenhum evento cadastrado</Typography>
          </Grid>
        )}
      </React.Fragment>
    );
  }

  renderManifestacoes(manifestacoes, classes) {
    return (
      <React.Fragment>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ padding: 10 }}
        >
          MANIFESTAÇÕES
        </Typography>
        <Grid container spacing={2} className={classes.detail}>
          {manifestacoes.map(({ data, manifestacao }) => {
            return (
              <Grid item sm={3} key={data}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      {"Registrado em: " + formataDataHora(data)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {"Descrição: " + manifestacao}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </React.Fragment>
    );
  }

  renderEventos(eventos, classes, processoId) {
    const eventosArray = Object.values(eventos);
    eventosArray.reverse();
    return (
      <React.Fragment>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ padding: 10 }}
        >
          EVENTOS DO PROCESSO
        </Typography>
        <Grid container spacing={2} className={classes.detail}>
          {eventosArray.map((evento, index) => {
            return (
              <Grid item sm={4} key={index}>
                <Card className={classes.root} style={{ minHeight: "220px" }}>
                  <CardHeader
                    style={{ paddingBottom: "5px", paddingLeft: "12px" }}
                    action={<CustomMenu options={[
                      {
                        label: 'Editar', action:()=> this.handleEditEvento(processoId, evento.id, evento)
                      },
                      {
                        label: 'Excluir',action:()=> this.handleConfirmOpen(
                          ()=> this.handleDeleteEvento(evento.id, processoId), 'Confirma a exclusão permanente do evento?'
                        ),
                      }
                    ]}></CustomMenu>}
                    subheader={
                      <Grid container spacing={5} className={classes.detail}>
                        <Grid item sm={10}>
                          <Typography>
                            {" "}
                            {"Evento - " + evento.titulo}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      {"Registrado em: " + formataDataHora(evento.data)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {"Descrição: " + evento.descricao}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {"Foi comunicado: " +
                        (evento.comunicacao ? "Sim" : "Não")}
                    </Typography>
                    <Grid style={{ paddingTop: 12 }}>
                      <AnexoEvento anexos={evento.anexos} somenteVisualizacao />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  juridico: { processos, escritoriosAdvocacia, loading },
  filiado: { filiados, loading: filiadoLoading },
  userState: { user },
}) => ({
  filiadoLoading,
  filiados,
  processos,
  loading,
  user,
  escritoriosAdvocacia,
});

Processos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setProcesso,
    excluirProcesso,
    encerrarProcesso,
    reabrirProcesso,
    deleteEventoProcesso,
    enviarParaManifestacaoSindilegis,
    getProcessos,
    setEscritorioAdvocaciaId,
    setEscritorioAdvocacia,
    getUser,
  })
)(withRouter(withSnackbar(Processos)));
