import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import tableI18n from "../TableLocalization";
import MaterialTable from "material-table";
import ConfirmDialog from "../commons/ConfirmDialog";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  getExpenses,
  setExpenseId,
  setExpense,
  atualizarDespesa,
} from "../../actions/financial";
import SindilegisBar from "../commons/SindilegisBar";
import LiquidacaoPagamento from "./liquidacao_pagamento";
import styles from "./styles";
import {
  converterParaNumero,
  somadorDouble,
  formatarValor,
  getCorDespesa,
  formataData
} from "./util";
import * as situacoes from "./situacoes";
import { FormControlLabel } from "@material-ui/core";
import FileUpload from "../commons/FileUpload";
import RangeDatePicker from "../commons/RangeDatePicker";


class ListaDespesas extends React.Component {
  state = {
    loading: true,
    despesas: [],
    naoAprovadasPorMim: true,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
    fromDate: new Date(2022, 10, 30),
    toDate: new Date(),
  };

  componentDidMount() {
    this.props.getExpenses((despesas) => this.atualizarState(despesas));
  }

  atualizarState(despesas) {
    const dataBase = new Date(2022, 10, 30).getTime();
    
      despesas = despesas.filter(
        (e) =>
          e.situacao === situacoes.AG_APROVACAO && e.data_vencimento > dataBase
      )
      despesas = _.sortBy(despesas, 'data_vencimento').reverse()
      despesas.length && this.setState({ despesas, despesasInit: despesas });
  }

  handleCheckBoxChange = () => {
    this.setState({ naoAprovadasPorMim: !this.state.naoAprovadasPorMim });
  };

  selecionarDespesa(event, id) {
    const { despesas } = this.state;
    const index = despesas.findIndex((d) => d.id === id);
    const despesa = {
      ...despesas[index],
      selecionado: !despesas[index].selecionado,
    };
    despesas.splice(index, 1, despesa);
    this.setState({ despesas: [...despesas], despesasInit: despesas });
    event.stopPropagation();
  }

  aprovarDespesas() {
    const { authUser } = this.props;
    const despesasAguardandoAprovacao = this.state.despesas.filter((d) => d.selecionado)
    despesasAguardandoAprovacao.forEach((d) => {
       if (d.aprovadores && d.aprovadores.length === 1) {
          this.props.atualizarDespesa(d.id, {
            situacao: situacoes.APROVADA,
            aprovadores: [...d.aprovadores, authUser.email],
          });
        } else {
          this.props.atualizarDespesa(d.id, { aprovadores: [authUser.email] });
        }
      });

      this.props.getExpenses((despesas) => this.atualizarState(despesas));
  }

  handleDateChange(date) {
    this.setState(date)
    console.log(date)
    this.filterDespesasData(date.fromDate.getTime(), date.toDate.getTime())
  }

  filterDespesasData(fromDate, toDate) {
    let despesas = this.state.despesasInit.filter(e => {
      return fromDate <= e.data_vencimento && toDate >= e.data_vencimento
    });
    this.setState({ despesas })
  }

  negarDespesas() {
    this.state.despesas
      .filter((d) => d.selecionado)
      .forEach((d) => {
        this.props.atualizarDespesa(d.id, { situacao: situacoes.NEGADA });
      });

      this.props.getExpenses((despesas) => this.atualizarState(despesas));
  }

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = confirm => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  render() {
    const { classes, authUser } = this.props;
    const { despesas, naoAprovadasPorMim } = this.state;
    const valoresSelecionados = despesas
      .filter((d) => d.selecionado)
      .map((e) => converterParaNumero(e.valor))
      .reduce(somadorDouble, 0);
    return (
      <div>
        <Fab variant="extended" aria-label="delete" className={classes.soma}>
          <NumberFormat
            value={valoresSelecionados}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={"R$ "}
          />
        </Fab>
        <SindilegisBar />
        <Container maxWidth="xl" className={classes.containerBottomPadding}>
          <Grid
            container
            sapacing={5}
            justifyContent="center"
            className={classes.areaOperacoes}
          >
            <Grid
              container
              item
              md={5}
              spacing={4}
              justifyContent="flex-start"
              alignItems="center"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={naoAprovadasPorMim}
                    onChange={() => this.handleCheckBoxChange()}
                  />
                }
                label="Somente despesas pendentes da minha aprovação"
              />
            </Grid>
            <Grid container item md={4} justifyContent="center" alignItems="center">
              <RangeDatePicker fromDate={this.state.fromDate} toDate={this.state.toDate} onChange={(data) => this.handleDateChange(data)} />
            </Grid>
            <Grid container item md={3} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  className={classes.button}
                  disabled={!despesas.filter((d) => d.selecionado).length}
                  color="secondary"
                  size="large"
                  onClick={() => this.handleConfirmOpen(() => this.negarDespesas(), 'Confirma a negação das despesas selecionadas?')}
                >
                  NEGAR
                </Button>
                <Button
                  className={classes.button}
                  disabled={!despesas.filter((d) => d.selecionado).length}
                  color="primary"
                  size="large"
                  onClick={() => this.handleConfirmOpen(() => this.aprovarDespesas(), 'Confirma a aprovação das despesas selecionadas?')}
                >
                  APROVAR
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <MaterialTable
            columns={[
              {
                title: '', field: 'selecionado', render: row => {
                  return (
                    <Checkbox
                      checked={!!row.selecionado}
                      onChange={(event) => this.selecionarDespesa(event, row.id)}
                      onClick={e => e.stopPropagation()}
                      inputProps={{
                        'aria-label': 'Selecionar despesa',
                      }}
                    />
                  );
                }
              },
              {
                title: 'Tipo', field: 'tipo_despesa', render: (row) => {
                  return (
                    <span style={{ color: getCorDespesa(row.data_vencimento, row.data_liquidacao_pagamento) }}>{row.tipo_despesa}</span>
                  );
                }
              },
              { title: 'Item', field: 'item_despesa' },
              { title: 'Beneficiário', field: 'nome_beneficiario' },
              { title: 'Vencimento', field: 'vencimento', render: (row) => <span>{formataData(row.data_vencimento)}</span> },
              { title: 'Parcela', field: 'parcela' },
              { title: 'Valor', field: 'valor', render: (row) => <span>{formatarValor(row.valor)}</span> },
            ]}
            data={this.state.despesas.filter((d) => !naoAprovadasPorMim || !d.aprovadores || !d.aprovadores.includes(authUser.email))}
            title="Despesas"
            detailPanel={row => this.renderDetail(row)}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: [10, 20, 50, 100]
            }}
            localization={tableI18n}
          />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Container>
      </div>
    );
  }

  renderDetail(despesa) {
    const { classes } = this.props;
    const { descricao, numero_parcelas, meio_pagamento, centro_custo, solicitante, id, anexos, data_liquidacao_pagamento, aprovadores } = despesa;
    return (
      <Grid container className={classes.despesa}>
          <Grid container spacing={4}>
            <Grid item sm={6}>
              <Typography variant="body2" color="textSecondary">
                Descrição
              </Typography>
              <Typography variant="body1">{descricao}</Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="body2" align="center" color="textSecondary">
                Nº de parcelas / Meio de pagamento
              </Typography>
              <Typography variant="body2" align="center" color="textPrimary">
                {`${numero_parcelas} / ${meio_pagamento}`}
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="body2" color="textSecondary">
                Centro de custo
              </Typography>
              <Typography variant="body1">{centro_custo}</Typography>
            </Grid>
            <Grid item container sm={12} spacing={2}>
              <LiquidacaoPagamento despesa={despesa} />
            </Grid>
            <Grid item container sm={12} spacing={2}>
              <FileUpload
                id={id}
                anexos={anexos}
                pagamentoLiquidado={!!data_liquidacao_pagamento}
              />
            </Grid>
            {aprovadores && (
              <Grid
                item
                container
                sm={6}
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography variant="body2" color="textSecondary">
                  Aprovador por:
                </Typography>
                <Typography variant="body1">{aprovadores.join(",")}</Typography>
              </Grid>
            )}
            {solicitante && (
              <Grid
                item
                container
                sm={6}
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography variant="body2" color="textSecondary">
                  Aprovação solicitada por:
                </Typography>
                <Typography variant="body1">{solicitante}</Typography>
              </Grid>
            )}
          </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ financial }) => ({
  expenses: financial.expenses,
  expenseId: financial.expenseId,
});

ListaDespesas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getExpenses,
    setExpenseId,
    setExpense,
    atualizarDespesa,
  })
)(withRouter(ListaDespesas));
