import React from "react";
import { Field, reduxForm, formValues } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import AutoCompleteSelect from "../commons/AutoComplete";
import DatePicker from "../commons/DatePicker";
import InputText from "../commons/InputText";
import Select from "../commons/Select";
import Switch from "../commons/Switch";
import { withSnackbar } from "notistack";
import styles from "./styles";
import { getInteracao, criarInteracao, saveInteracao, setInteracaoId, setInteracao } from "../../actions/interacao";
import { getDependents } from "../../actions/dependent";
import { getItensInteracao } from "../../actions/item_interacao";
import { getFiliado, setFiliadoId } from "../../actions/filiado";
import { getEscritoriosAdvocacia } from "../../actions/juridico";

class InteracaoForm extends React.Component {

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.props.getItensInteracao();
    this.props.getEscritoriosAdvocacia();
  }

  componentWillReceiveProps({ interacaoId, filiadoId }) {
    if (interacaoId) {
      this.props.getDependents(interacaoId.split('/')[0]);
    this.props.getInteracao(interacaoId);
    }
    if (filiadoId) {
    this.props.getDependents(filiadoId);
  }
}

  save(interacao) {
    const { interacaoId, filiadoId } = this.props;
    if (interacaoId) {
      this.props.saveInteracao(interacaoId, interacao, () => this.successHandler())
    } else {
      this.props.criarInteracao(filiadoId, interacao, () => this.successHandler(true))
    }
  }

  successHandler(reload) {
    const { handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar("Interação salva com sucesso!", {
      variant: "success"
    });
    this.props.setFiliadoId(null);
    this.props.setInteracao(null);
    this.props.setInteracaoId(null);
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Erro ao salvar interação.", {
      variant: "error"
    })
  }

  handleChangeItemInteracao(item) {
    const item_interacao = this.props.itens_interacao.find(i => i.nome === item);
    if (item_interacao) {
      const { tipo, descricao } = item_interacao;
      tipo && this.props.change('tipo', tipo);
      descricao && this.props.change('descricao', descricao);
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, reset, dependents, itens_interacao, escritoriosAdvocacia } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Interação</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid
              container
              spacing={2}
              hidden={false}
            >
              <Grid item sm={6}>
                <Field
                  name="item_interacao"
                  options={itens_interacao.filter(item => !item.desabilitado).map(d => ({ label: d.nome, value: d.nome }))}
                  component={AutoCompleteSelect}
                  label="Item de interação"
                  onChange={(item) => this.handleChangeItemInteracao(item)}
                />
              </Grid>
              <DadosEscritorios escritorios={escritoriosAdvocacia} />
              <Grid item sm={2}>
                <Field
                  name="data"
                  component={DatePicker}
                  label="Data"
                />
              </Grid>
              <Grid item sm={12}>
                <Field
                  name="descricao"
                  component={InputText}
                  label="Descrição"
                />
              </Grid>
              <Grid item container sm={12} spacing={2}>
                <Grid item sm={4}>
                  <Field name="relacao_dependente" component={Switch} label="Interação relacionada a dependente?" />
                </Grid>
                <DadosDependente dependentes={dependents} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => reset() && this.props.handleClose()}
              color="default"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog >
    );
  }
}

const DadosEscritorios = formValues('item_interacao')(({ item_interacao, escritorios }) => {
  if (item_interacao === 'Assistência jurídica') {
    return (
      <React.Fragment>
        <Grid item sm={4}>
          <Field
            name="tipo"
            component={Select}
            label="Escritório"
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {escritorios.map(d => (
              <MenuItem key={d.id} value={d.nome}>
                {d.nome}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </React.Fragment>
    )
  }
  return null
});


const DadosDependente = formValues('relacao_dependente')(({ relacao_dependente, dependentes }) => {
  if (relacao_dependente) {
    return (
      <React.Fragment>
        <Grid item sm={8}>
          <Field
            name="id_dependente"
            component={Select}
            label="Nome do dependente"
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {dependentes.map(d => (
              <MenuItem key={d.id} value={d.id}>
                {d.nome_completo}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </React.Fragment>
    )
  }
  return null
});

InteracaoForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({
  interacao: { interacao, interacaoId },
  item_interacao: { itens_interacao },
  filiado: { filiado, filiadoId },
  dependent: { dependents },
  juridico: { escritoriosAdvocacia },
}) => ({
  dependents,
  filiado,
  filiadoId,
  itens_interacao,
  interacao,
  interacaoId,
  escritoriosAdvocacia,
  initialValues: interacao
});

const validate = values => {
  const errors = {};
  const requiredFields = [
    "item_interacao",
    "tipo",
    "data",
    "descricao",
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = " Obrigatório";
    }
  });
  return errors;
};

export default compose(
  connect(
    mapStateToProps,
    { getInteracao, criarInteracao, saveInteracao, getFiliado, setInteracaoId, setInteracao, setFiliadoId, getDependents, getItensInteracao, getEscritoriosAdvocacia }
  ),
  withStyles(styles)
)(
  reduxForm({
    form: "interacao",
    validate,
    enableReinitialize: true,
  })(withSnackbar(InteracaoForm))
);
